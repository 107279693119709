import React, { Component } from "react";
import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import axios from "axios";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import ScrollAnimation from 'react-animate-on-scroll';
import Egypt from '../../../assets/img/egypt1.png';
import Canada from '../../../assets/img/canada1.png';
import usa from '../../../assets/img/usa.png';
import { Alert } from 'reactstrap';
class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CONTACT_NAME: "",
            CONTACT_EMAIL: "",
            CONTACT_SUBJECT: "",
            CONTACT_PHONE: "",
            CONTACT_MESSAGE: "",
            successMsg: ""
        };
    }

    
    handleForm = e => {
        let that = this;
        axios
            .post("https://backend.itlandgroup.com/itlandServices/addContactUs", this.state, {
                headers: { Accept: "application/json" }
            })
            .then(function(response) {
                console.log(response);
                document.getElementById("contactForm").reset();
                that.setState({
                    // [e.target.name]: e.target.value,
                    successMsg: "Thank you! We received your message."
                });
                document.getElementById("contactForm").reset();
            }) 
            .catch(function(error) {});
    }; 

    handleFields = e => this.setState({ [e.target.name]: e.target.value });
    mySubmitHandler = (event) => {
       
        // alert("<h1>Thank you for submitting We will be in Touch</h1>");
      }
    render() {
        return (
            <React.Fragment>
            <section Style=" background-color: #FCF6F6;" id="contact" className="contact-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                    </div>
                    </div>
                   
                    <div className="row">
                            <div className="col-lg-6 col-md-6">
                                            <div className="address-area">
                                                        <div className="addess">
                                                                <Icofont icon="icofont-google-map"/>
                                                                <h4> {this.props.AddTitle}</h4>
                                                                <p> <img src={Canada} alt ="Canda_Icon"  Style="width: 6%;" />  {this.props.Address}</p>
                                                                <p> <img src={Egypt} alt ="Egypt_Icon"  Style="width: 6%;" />  {this.props.Address1}</p>
                                                                <p> <img src={usa} alt ="usa_Icon"  Style="width: 6%;" />  {this.props.Address2}</p>
                                                        </div>
                                            </div>
                                            <div className="address-area">
                                            <div className="email">
                                                <Icofont icon="icofont-email"/>
                                                <h4>{this.props.EmailTitle}</h4>
                                                <p>{this.props.Email}</p>
                                            </div>
                                            </div>
                                             <div className="address-area">
                                            <div className="phone">
                                                <Icofont icon="icofont-phone"/>
                                                <h4>{this.props.PhoneTitle}</h4>
                                                <p><img src={Canada} alt ="Canda_Icon"  Style="width: 6%;" /> {this.props.Phone}</p>
                                                <p><img src={Egypt} alt ="Egypt_Icon"  Style="width: 6%;" />{this.props.Phone1}</p>
                                                <p><img src={usa} alt ="usa_Icon"  Style="width: 6%;" /> {this.props.Phone2}</p>
                                            </div>
                                            </div>
                            </div>
                
                        <div className="col-lg-6 col-md-6">
                            <div className="contact-form">
                                <ValidationForm
                                    id="contactForm"
                                    onSubmit={(e, formData, inputs) => {
                                        e.preventDefault();
                                        this.handleForm(formData);
                                        this.mySubmitHandler();
                                    }}
                                >
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                                    <TextInput
                                                        name="CONTACT_NAME"
                                                        id="name"
                                                        required
                                                        successMessage=""
                                                        errorMessage="Please enter your name"
                                                        className="form-control"
                                                        placeholder="Name"
                                                        autoComplete="off"
                                                        
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                    <div className="help-block with-errors" />
                                                </div>
                                        </div>
                                     
                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                        <TextInput
                                                            name="CONTACT_EMAIL"
                                                            id="email"
                                                            type="email"
                                                            required
                                                            successMessage=""
                                                            errorMessage="Please enter your email address"
                                                            className="form-control"
                                                            placeholder="Email"
                                                            autoComplete="off"
                                                            onChange={
                                                                this.handleFields
                                                            }
                                                        />
                                                        <div className="help-block with-errors" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="CONTACT_SUBJECT"
                                                        id="subject"
                                                        type="text"
                                                        successMessage=""
                                                        errorMessage="Please enter your email subject"
                                                        className="form-control"
                                                        placeholder="Subject"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="CONTACT_PHONE"
                                                        id="number"
                                                        type="text"
                                                        successMessage=""
                                                        errorMessage="Please enter your phone number"
                                                        className="form-control"
                                                        placeholder="Phone"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>

                                       
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="CONTACT_MESSAGE"
                                                        id="description"
                                                        multiline
                                                        placeholder="Your message"
                                                        className="contact-out-message"
                                                        required
                                                        successMessage=""
                                                        errorMessage="Please write your message"
                                                        rows="5"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                        
                                                    />
                                                </div>
                                            </div>
                                    </div>

                                    <div className="center-wrap">
                                            <div className="button contactbutton">
                                                <button type="submit">Submit<Icofont icon="icofont-long-arrow-right"/> </button>
                                                <div className="mask"></div>
                                            </div>
                                    </div>
                                    <div className="clearfix" />
                                </ValidationForm>

                                
                                {this.state.successMsg !== "" ? (
                                    <div className="col-md-12"> 
                                        <div id="contact_send_status">
                                        <Alert color="success">
                                                  {this.state.successMsg}
                                                </Alert>
                                        </div>
                                     </div>
                                ) : null}
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-4"> 
                                <img src={imageLogo} alt="itlandLogo" ></img>
                        </div> */}
                    </div>
                </div>
                
                    
                
            </section>
        </React.Fragment>
        );
    }
}

//Props Types
Contact.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    //sectionDescription: PropTypes.string,
    AddTitle: PropTypes.string,
    Address: PropTypes.string,
    Address1: PropTypes.string,
    Address2: PropTypes.string,
    EmailTitle: PropTypes.string,
    Email: PropTypes.string,
    PhoneTitle: PropTypes.string,
    Phone: PropTypes.string,
    Phone1: PropTypes.string,
    Phone2: PropTypes.string,
};

//Default Props
Contact.defaultProps = {
    SectionbgTitle: "Contact",
    sectionTitle: "Contact Us",
    //sectionDescription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.",
    AddTitle: "Address",
    Address: "503-101 Kozlov Street Barrie Ontario- L4N 5L7, Canada.",
    Address1: "45 Khalil el Khayat St., Kafr Abdo, Alexandria, Egypt",
    Address2: "62 Buffumsville Road, Somersworth, New Hampshire 03878, USA",
    EmailTitle: "Email",
    Email: "mail@itlandgroup.com ",
    PhoneTitle: "Phone",
    Phone: "+1-705-500-2752",
    Phone1: "+20-155-1785-404    +20-3543-6921   ",
    Phone2:"+1-603-380-5751",
};
export default Contact;
