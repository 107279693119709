import React, { Component } from "react";
import PropTypes from "prop-types";
import Icofont from 'react-icofont';
import axios from "axios";
import { ValidationForm, TextInput  } from "react-bootstrap4-form-validation";
import ScrollAnimation from 'react-animate-on-scroll';
import { Alert } from 'reactstrap';

// import imageLogo from "../../../assets/img/logos/logoLoginModal.png";
class Quote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            QUOTE_NAME: "", 
            QUOTE_COMPANY: "",
            QUOTE_EMAIL: "",
            QUOTE_SERVICES: "",
            QUOTE_BUDGET: "",
            QUOTE_DETAILS: "",
            successMsg: ""
        };
    }
    handleForm = e => {
        let that = this;
        axios
            .post("https://backend.itlandgroup.com/itlandServices/addFreeQuote", this.state, {
                headers: { Accept: "application/json" }
            })
            .then(function(response) {
                console.log(response);
                document.getElementById("contactForm").reset();
               
                that.setState({
                    // [e.target.name]: e.target.value,
                    successMsg: "Thank you! We received your message"
                    
                });
              
                document.getElementById("contactForm").reset();
            })
            .catch(function(error) {});
    };

    handleFields = e => this.setState({ [e.target.name]: e.target.value } ) ;
    mySubmitHandler = (event) => {
       
        // alert("Thank you for submitting We will be in Touch");
      }

      
    render() {
        
        return (
            <React.Fragment>
                <section Style=" background-color: #FCF6F6;" id="free-quote" className="contact-area ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 text-center">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="section-title">
                                        <h2>{this.props.sectionTitle}</h2>
                                        <p>{this.props.sectionDescription}</p>
                                        <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                    </div>
                                </ScrollAnimation>
                        </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="contact-form">
                                    <ValidationForm
                                        id="contactForm"
                                        onSubmit={(e, formData, inputs) => {
                                            e.preventDefault();
                                            this.handleForm(formData);
                                            this.mySubmitHandler();
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="QUOTE_NAME"
                                                        id="name"
                                                        required
                                                        successMessage=""
                                                        errorMessage="Please enter your name"
                                                        className="form-control"
                                                        placeholder="Please enter your name"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </div>
                                         
                                            <div className="col-lg-6 col-md-12">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="QUOTE_COMPANY"
                                                        id="subject"
                                                        type="text"
                                                        successMessage=""
                                                        errorMessage="Please enter your email subject"
                                                        className="form-control"
                                                        placeholder="Please enter your Company name"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                            
                                                 <div className="form-group">
                                                    <TextInput
                                                        name="QUOTE_EMAIL"
                                                        id="email"
                                                        type="email"
                                                        required
                                                        successMessage=""
                                                        errorMessage="Please enter your email address"
                                                        className="form-control"
                                                        placeholder="Please enter your email address "
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                    <div className="help-block with-errors" />
                                                </div>
                                            </div>

                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                 
                                                    {/* <label for="exampleFormControlSelect1">Needed Services</label> */}
                                                            <select name = "QUOTE_SERVICES" class="form-control" id="exampleFormControlSelect1" onChange={
                                                            this.handleFields
                                                        }>
                                                            <option value="" selected disabled>Needed Services</option>
                                                            <option value="Website Development and Support" >Website Development and Support</option>
                                                            <option value="Mobile Application">Mobile Application</option>
                                                            <option value="Small Business Solutions">Small Business Solutions</option>
                                                            <option value="Enterprise Solutions">Enterprise Solutions</option>
                                                            <option value="E-Commerce Solutions">E-Commerce Solutions</option>
                                                            <option value="Digital Marketing">Digital Marketing</option>
                                                            </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="form-group">
                                                   
                                                       <select name= "QUOTE_BUDGET" class="form-control" id="exampleFormControlSelect1" onChange={
                                                            this.handleFields
                                                        }>
                                                            <option value="" selected disabled>BUDGET</option>
                                                            <option value="Under1,000">Under $1,000</option>
                                                            <option value="Between $1,000 - $3,000" >Between $1,000 - $3,000</option>
                                                            <option value="Between $3,000 - $10,000" >Between $3,000 - $10,000</option>
                                                            <option value="Over$10,000">Over $10,000</option>
                                                            </select>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <TextInput
                                                        name="QUOTE_DETAILS"
                                                        id="description"
                                                        multiline
                                                        placeholder="Please add any details you think it would be useful for us to make a correct estimation"
                                                        className="form-control"
                                                        successMessage=""
                                                        errorMessage="Please write your message"
                                                        rows="5"
                                                        autoComplete="off"
                                                        onChange={
                                                            this.handleFields
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="center-wrap">
                                            <div className="button">
                                                <button type="submit">GET A QUOTE NOW! <Icofont icon="icofont-long-arrow-right"/> </button>
                                                <div className="mask"></div>
                                            </div>
                                        </div>
                                        <div className="clearfix" />
                                        
                                        

                                    </ValidationForm>
                                    {this.state.successMsg !== "" ? (
                                        <div className="col-md-12"> 
                                            <div id="contact_send_status">
                                                {/* <h3 className="contactMsg">
                                                     {this.state.successMsg}
                                                </h3> */}
                                                <Alert color="success">
                                                  {this.state.successMsg}
                                                </Alert>
                                               
                                            </div>
                                         </div>
                                    ) : null}
                                </div>
                            </div>
                            {/* <div className="col-lg-4 col-md-4"> 
                                    <img src={imageLogo} alt="itlandLogo" ></img>
                            </div> */}
                        </div>
                    </div>
                    
                        
                    
                </section>
            </React.Fragment>
        );
    }
}

//Props Types
Quote.propTypes = {
    //SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    AddTitle: PropTypes.string,
    Address: PropTypes.string,
    EmailTitle: PropTypes.string,
    Email: PropTypes.string,
    PhoneTitle: PropTypes.string,
    Phone: PropTypes.string,
};

//Default Props
Quote.defaultProps = {
    SectionbgTitle: "FREE QUOTE",
    sectionTitle: "GET A FREE QUOTE",
    sectionDescription: "Request one of our services Website Development | Mobile Applications | Small Business Solutions | Enterprise Solutions | E-Commerce Solutions | Digital Marketing ",

};

export default Quote;