import React from 'react';
import { withRouter } from "react-router-dom";
import NavBar from "./components/NavBar";
import Services from "./components/Services";
import BannerThree from "./components/banner/BannerThree";
import Footer from "./components/Footer";
import Works from "./components/Works";
import Offer from "./components/Offer";
import Team from "./components/Team";
import Contact from "./components/Contact";
import { Element } from 'react-scroll';
import Quote from "./components/Quote";
import Pricing from "./components/Pricing";
import Process from "./components/Process";
import WelcomeServices from "./components/Cost";


function MobileApplication(props) { 

  return (
    <React.Fragment>
     <NavBar pageName="home" /> 
          <BannerThree />

          {/* Services: src/components/Services.jsx */}
    <Element id='example-destination' name='example-destination'>
      <Services />
    </Element>
   {/* Process: src/components/Process.jsx */}
   <Process />
    {/* Works: src/components/Works.jsx */}
    <Works />
      {/* About: src/components/Offer.jsx */}
      <Offer />
    {/* Team: src/components/Team.jsx */}
    <Team />
    
     {/* WelcomeServices: src/components/WelcomeServices.jsx */}
     <WelcomeServices />
        {/* Pricing: src/components/Pricing.jsx */}
        <Pricing /> 

{/* quote: src/components/Quote.jsx */}
<Quote />
     {/* Contact: src/components/Contact.jsx */}
     <Contact />

    <Footer />
</React.Fragment>
  );
}








export default withRouter(MobileApplication);