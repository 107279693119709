import React, { Component } from "react";
import PropTypes from "prop-types";
// import Icofont from 'react-icofont';
// import { Link } from 'react-router-dom';
import tick from "./../../../../assets/img/tick.png";

export class Item extends Component {
    render() {
        //Start ItemOne Loop
        const itemonedata = this.props.itemonesData.map((itemone, index) => (
            
            <div className={itemone.className} key={index}>
                <div className={itemone.FeaturedClass}>
                    <span className="featured">{itemone.Featured}</span>
                </div>
                <div className="plan-price">
                    <h3>{itemone.planName}</h3>
                    <span className="value">{itemone.price}</span>
                    {/* <span className="period">{itemone.description}</span> */}
                    <hr />
                </div>

                <div className="plan-features" >
                    <ul Style="align-text: left;">
                        <li><img src={tick} alt =""  Style="width: 3%;" /> {itemone.content1}</li>
                        <hr />
                        <li><img src={tick} alt =""  Style="width: 3%;" /> {itemone.content2}</li>
                        <hr />
                        <li><img src={tick} alt =""  Style="width: 3%;" /> {itemone.content3}</li>
                        <hr />
                        <li><img src={tick} alt =""  Style="width: 3%;" /> {itemone.content4}</li>
                        <hr />
                        <li><img src={tick} alt =""  Style="width: 3%;" /> {itemone.content5}</li>
                       
                    </ul>
                    {/* <div className="center-wrap">
                        <Link to={itemone.btnlink} className="btn-a">
                            <div className="button">
                                {itemone.BtnName}
                                <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" /></div>
                        </Link>
                    </div> */}
                </div>
            </div>
            
        ));
        //End ItemOne Loop
        return (
        <React.Fragment>
            <div role="tabpanel" className="tab-pane fade show active" id="monthly">
                <div className="pricing-container margin-top-60">
                    {itemonedata}           
                </div>
            </div>
        </React.Fragment>
        );
    }
}
Item.PropsTypes = {
    itemonesData: PropTypes.array
};
Item.defaultProps = {
    itemonesData: [
        {
            planName: "Aggressive plan",
            className: "plan",
            description: "$5,500 INITIAL INVESTMENT",
            FeaturedClass: "",
            Featured: "",
            price: "$500/month",
            content1: "5 pages-one guranteed keyphrases",
            content2: "1 custom dashboards",   
            content3: "2 pages of SEO copy",
            content4: "Keyword rank checker access",
            content5: "3 links or content assets",
           // content6: "$5000 - $8000",
            // BtnName: "Purchase Now",
            // btnlink: "/#0 ",
        },
        {
            planName: "Market Leader plan",
           className: "plan featured",
            description: "$6,000-$6,500 INITIAL INVESTMENT",
            FeaturedClass: "listing-badges",
            Featured: "Featured",
           price: "$1,500/month",
            content1: "15 page-one guaranteed keyphrases",
            content2: "5 custom dashboards",
            content3: "7 pages of SEO copy",
            content4: "Shopping cart funnel analysis",
            content5: "7 links or content assets",
           // content6: "$19000 - $25000",
            //content7: "Enhanced Security",
            // BtnName: "Purchase Now",
            // btnlink: "/#0",
        },
        {
            planName: "TRAILBLAZER PLAN",
            className: "plan",
            description: "$8,000 INITIAL INVESTMENT",
            FeaturedClass: "",
            Featured: "",
            price: "$2,800/month",
            content1: "30 page-one guaranteed keyphrases",
            content2: "4 custom dashboards",
            content3: "15 pages of SEO copy",
            content4: "Customer review strategy",
            content5: "16 links or content assets",
            //content6: "$61000 - $80000",
            // BtnName: "Purchase Now",
            // btnlink: "/#0",
        },
        
    ]
};
export default Item;
