import React, { Component } from "react";
import PropTypes from "prop-types";


export class ItemTwo extends Component {
    render() {
        //Start ItemTwo Loop
        const itemtwodata = this.props.itemtwosData.map((itemtwo, index) => (
            
            <div className={itemtwo.className} key={index}>
                <div className={itemtwo.FeaturedClass}>
                    <span className="featured">{itemtwo.Featured}</span>
                </div>
                <div className="plan-price">
                    <h3>{itemtwo.planName}</h3>
                    <span className="value">{itemtwo.price}</span>
                    <span className="period">{itemtwo.description}</span>
                </div>

                <div className="plan-features">
                    <ul>
                        <li>{itemtwo.content1}</li>
                        <li>{itemtwo.content2}</li>
                        <hr />
                        <li>{itemtwo.content3}</li>
                        <li>{itemtwo.content4}</li>
                        <hr />
                        <li>{itemtwo.content5}</li>
                        <li>{itemtwo.content6}</li>
                        <hr />
                        <li>{itemtwo.content7}</li>
                        <li>{itemtwo.content8}</li>
                    </ul>
                </div>
            </div>
            
        ));
        //End ItemTwo Loop
        return (
        <React.Fragment>
            <div role="tabpanel" className="tab-pane fade show active" id="monthly">
                <div className="pricing-container margin-top-60">
                    {itemtwodata}           
                </div>
            </div>
        </React.Fragment>
        );
    }
}
ItemTwo.PropsTypes = {
    itemtwosData: PropTypes.array
};
ItemTwo.defaultProps = {
    itemtwosData: [
        {
            planName: "Basic",
            className: "plan",
            //description: "Free of charge one standard listing active for 30 days",
            FeaturedClass: "",
            Featured: "",
            price: "",
            content1: "1-3 pages",
            content2: "$500 - $1,500",   
            content3: "4-7 pages",
            content4: "$1,800 - $3,800",
            content5: "7-10 pages",
            content6: "$3,900 - $6,500",
            // BtnName: "Purchase Now",
            // btnlink: "/#0 ",
        },
        {
            planName: "Advanced",
           className: "plan featured",
            //description: "One time fee for one listing, highlighted in the search results",
            FeaturedClass: "listing-badges",
            Featured: "Featured",
           // price: "$145",
            content1: "11-15 pages",
            content2: "$6,800 - $9,500",
            content3: "16-20 pages",
            content4: "$96,000 - $14,500",
            content5: "21-25 pages",
            content6: "$14,600 - $21,000",
            //content7: "Enhanced Security",
            // BtnName: "Purchase Now",
            // btnlink: "/#0",
        },
        {
            planName: "Enterprise",
            className: "plan",
           // description: "Monthly subscription for unlimited listings and availability",
            FeaturedClass: "",
            Featured: "",
            //price: "$555",
            content1: "26-30 pages",
            content2: "$22,000 - $32,000",
            content3: "31-40 pages",
            content4: "$32,000 - $50,000",
            content5: "41-50 pages",
            content6: "$51,000 - $75,000",
          
            
        },
        
        
    ]
};
export default ItemTwo;
