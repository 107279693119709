import React, { Component } from 'react';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';
// import Icofont from 'react-icofont';
// import { Link } from 'react-router-dom';
// import tick from "../../../assets/img/tick.png";
import process from "../../../assets/img/fg.jpg";
import "react-modal-video/css/modal-video.min.css";


class Process extends Component {
   
        render() {
        return (
            <React.Fragment>
        <section id="process" className="cta-areacost ">
        <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    {/* <p>{this.props.sectionDescription}</p> */}
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
       
                 <img src={process} alt="website Process" />
            
        </section>
        </React.Fragment>
        );

    }

}
Process.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
   
};
Process.defaultProps = {
    SectionbgTitle: "The Process",
    sectionTitle: "The Process",
};

export default Process;