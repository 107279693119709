import React, { Component } from 'react';
// import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';
import tick from "../../../assets/img/tick.png";
import erp from "../../../assets/img/erp.png";

class About extends Component {
  render() {
      //Service loop start
      const servicedata = this.props.servicesData.map((service, index) => (
        <div className="col-md-6 col-lg-4 text-center" key={index}>
            <div className="service-item">
               
                <h3>{service.heading}</h3>
                <p>{service.description}</p>
            </div>
        </div>
    ));
    //Service loop END 
    return (
        <React.Fragment>
            <section id="offers" className="services ptb-100">
                <div className="container"> 
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div Style="margin-bottom: 150px;" className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                    
                        {servicedata}
                    </div>
                    <div className="row">
                   <div > <h2 >{this.props.Title}</h2></div>
                   <hr/>
                        
                   <div className="col-sm-4">   
                                
                                  <div Style="margin left: 100px">
                                       <h4><img src={tick} alt =""  Style="width: 2%;" /> Brand Strategy</h4>
                                        <h4><img src={tick} alt =""  Style="width: 2%;" /> Brand Guideline</h4>
                                        <h4><img src={tick} alt =""  Style="width: 2%;" /> Corporate Identity Design</h4>
                                        <h4><img src={tick} alt =""  Style="width: 2%;" /> Stationery Design</h4>
                                        <h4><img src={tick} alt =""  Style="width: 2%;" /> Logo Design</h4>
                                       <h4><img src={tick} alt =""  Style="width: 2%;" /> Advertisement Design</h4>
                                       <h4><img src={tick} alt =""  Style="width: 2%;" /> Brochures</h4>
                                       <h4><img src={tick} alt =""  Style="width: 2%;" /> Letterhead & Stationary</h4>
                                       </div>
                                       </div>
                                       <div className="col-sm-4">
                                       <div className="imgBx">
                                     <img Style=" margin-left:-5px;"className="aya123" src={erp} alt="images" />
                               </div>
                                  </div>
                                        <div className="col-sm-4">
                                        <div className="ao">
                                  <h4><img src={tick} alt =""  Style="width: 2%;" /> Sales & Presentation Folders</h4>
                                  <h4><img src={tick} alt =""  Style="width: 2%;" /> Powerpoint Templates</h4>          
                                  <h4><img src={tick} alt =""  Style="width: 2%;" /> Ads Developments</h4>
                                  <h4><img src={tick} alt =""  Style="width: 2%;" /> Social Media Design</h4>
                                  <h4><img src={tick} alt =""  Style="width: 2%;" /> Vehicle graphics design</h4>
                                  <h4><img src={tick} alt =""  Style="width: 2%;" /> Infographics design</h4>
                                  <h4><img src={tick} alt =""  Style="width: 2%;" /> 3D Models and renderings</h4>
                                  </div>
                                  </div>
                                       
                                           
                                        </div>
                                        </div>
                                    
            </section>
        </React.Fragment>
    );
  }
}

//Props Types
About.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
About.defaultProps = {
    SectionbgTitle: "Offers",
    sectionTitle: "What We Offer!",
    sectionDescription:
        "Capture more leads with a conversion-focused website custom-designed to help you achieve your business goals and objectives.",

    servicesData: [
        // {
        //     icon: "icofont-code",
        //     heading: "MARKET RESEARCH",
        //     description:
        //         "Market research can help you find out exactly the type of people most interested in your brand. Those are the people that you need to be targeting. Both to reinforce existing loyalties and grow new customers in the most fertile soil. For existing brands, this can sometimes lead to a harsh surprise-perhaps the research will reveal that a previously ignored demographic is your most loyal customer. This new information can be used for your brand strategy."
        // },
        // {
        //     icon: "icofont-interface",
        //     heading: "BRAND STRATEGY",
        //     description:
        //         "Our strategic approach to branding ensures your identity is differentiated from that of your competitors and will add value to your company’s products and services by creating a unique distinctiveness within the market. From the market research information, we ultimately uncover the unique value you bring to your customers, to create and develop a brand that is truly representative of your company and its products and services."
        // },
        // {
        //     icon: "icofont-look",
        //     heading: "CORPORATE IDENTITY",
        //     description:
        //         "Whether you are starting a new business or running a successful one, your corporate identity forms the basis for communicating your brand it is, therefore, imperative that it represents the quality of your company, its goals and what it stands for. Our graphic design team brings the highest levels of experience to assist from concept to implementation. Being a full-service design agency allows us to ensure that our high standards are consistent."
        // },
        
    ]
};

export default About;
