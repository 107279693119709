import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';
import erp from "../../../assets/img/erp.png";

class Services extends Component {
  render() {
      //Service loop start
      const servicedata = this.props.servicesData.map((service, index) => (
        <div className="col-md-6 col-lg-4 text-center" key={index}>
            <div className="service-item">
                <div className="glyph">
                    <Icofont icon={service.icon} />
                </div>
                <h3>{service.heading}</h3>
                <p>{service.description}</p>
            </div>
        </div>
    ));
    //Service loop END
    return (
        <React.Fragment>
            <section id="services" className="services ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    {/* <p>{this.props.sectionDescription}</p> */}
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                        <div className="imgBx">
                             <img className="aya1" src={erp} alt="images" />
                        </div>
                        
                        {servicedata}
                 
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
  }
}

//Props Types
Services.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Services.defaultProps = {
    SectionbgTitle: "Services",
    sectionTitle: "Services",
    sectionDescription:
        "Capture more leads with a conversion-focused website custom-designed to help you achieve your business goals and objectives.",

        servicesData: [
        
            {
                icon: "icofont-interface",
                heading: "Website Design",
                description:
                    "Your website is the lifeblood of your company, the face of your business. We design elegant, user-friendly websites that showcase your brand and / or product. A well-designed website attracts visitors and converts them to customers."
            },
            {
                icon: "icofont-code",
                heading: "Content Development",
                description:
                    "Your website’s content is king. Well-designed and written content peaks your audience’s interest and engages consumers. Let us help you develop a strong content strategy to help you connect with your customers and build your business."
            },
            {
                icon: "icofont-mail",
                heading: "Email Marketing",
                description:
                    "We can assist you with the development of an email marketing strategy. Building a deep, targeted email list and creating campaigns that positively impact these audiences can take your business from zero to hero."
            },
            {
                icon: "icofont-search-document",
                heading: "Search Engine Optimization SEO",
                description:
                    "SEO strategy and execution is a constantly evolving science. We’ll help you to stay on top of your SEO and keep you at the top of the search engines. Customers are out there looking for you – let’s make sure they find you."
            },
            {
                icon: "icofont-search-2",
                heading: "Social Media Marketing SMM",
                description:
                    "Social media marketing and its successes grow year after year. There are a number of different social channels and strategies to consider. We’ll help you identify where and how to best reach your potential customers through social media."
            },
    ]
};

export default Services;
