import React, { Component } from 'react';
// import Icofont from 'react-icofont';
// import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
// import tick from '../../../../assets/img/tick.png';
// import videoaya from "../../assets/video.wmv"
import img from "../../../../assets/img/Comp-33.gif";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
class BannerThree extends Component {
    render() {
        return (
            <React.Fragment>
                <div id="home" className="home-video-area">
                {/* <video id="bgvid" loop autoPlay muted>
                    <source src="http://taxify.co/wp-content/uploads/2014/06/file.mp4" type="video/mp4" />
                </video> */}
                 
            <div class="carousel-wrapper">
            <Carousel showArrows={true} showIndicators={false} showThumbs={false} showStatus={false} autoPlay={true} >
                <div>
                <img src={img} alt="MISSING JPG"/> 
                </div>
                {/* <div>
                <img src={img} alt="Hong Kong" />
                </div>
                <div>
                <img src={img} alt="Hong Kong" />
                </div> */}
            </Carousel>
        </div>
                
                    <div className="video-text-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <span className="hero-text animated fadeInDown slow opacityOne">
                                        {this.props.TopTitle}
                                    </span>
                                    <div className="container">
                                    <h1
                                        className="animated fadeInDown slow opacityOne ayasmall">
                                        {this.props.Title}
                                    </h1>
                                    </div>
                                    <div className="row">
                                    <p
                                    className=" animated fadeInDown slow opacityOne osamasmall " Style="margin-top: -150px;">
                                          {this.props.Title1}
                                        </p>
                                     </div>
                                     <br/>
                                   
                                    <p
                                        className="animated fadeInDown slow opacityOne ayanawar" Style="margin-top: -30px; font-size: 15px;">
                                             <ul>
                                   <li>{this.props.Content}</li>
                                    <li> {this.props.Content1}</li>
                                   <li> {this.props.Content2}</li>
                                       </ul>
                                        
                                    </p>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </React.Fragment>
        );
    }
}
//Props Types
BannerThree.propTypes = {
    Title: PropTypes.string,
 //   Title1: PropTypes.string,
    TopTitle: PropTypes.string,
   
    Content: PropTypes.string,
    // Content1: PropTypes.string,
    // Content2: PropTypes.string,
    //BtnLink: PropTypes.string,
   // BtnName: PropTypes.string,
};

//Default Props
BannerThree.defaultProps = {
    TopTitle: "",
    Title:"Ready to take your business to the next level?" ,
   // Title1:"" ,
   Content: "Digital strategies start with a website, but they certainly don’t end there. Once we help you with an amazing website design, we can go above and beyond to help with your Software Apps, digital marketing & content strategies as well. Reach out to see how we can help. Contact us today to further discuss your Software App, website and/or digital marketing needs!",
//    Content1: "Responsive design optimized for all browsers and devices .",
//    Content2: "User-friendly Content Management System (CMS) makes editing & adding new content a breeze.",
   // BtnLink:  "/#",
   // BtnName: "webSiteDevelopment",
};
export default BannerThree;
