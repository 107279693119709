import React, { Component } from "react";
import PropTypes from "prop-types";
// import Icofont from 'react-icofont';
// import { Link } from 'react-router-dom';

export class ItemOne extends Component {
    render() {
        //Start ItemOne Loop
        const itemonedata = this.props.itemonesData.map((itemone, index) => (
            
            <div className={itemone.className} key={index}>
                <div className={itemone.FeaturedClass}>
                    <span className="featured">{itemone.Featured}</span>
                </div>
                <div className="plan-price">
                    <h3>{itemone.planName}</h3>
                    <span className="value">{itemone.price}</span>
                    <hr />
                    {/* <span className="period">{itemone.description}</span> */}
                </div>

                <div className="plan-features">
                    <ul>
                        <li>{itemone.content1}</li>
                        <li>{itemone.content2}</li>
                        <li>{itemone.content3}</li>
                        <hr />
                        <li Style="font-weight: bold;">{itemone.content4}</li>
                        <li Style="ont-weight: bold;">{itemone.content5}</li>
                       
                    </ul>
                    {/* <div className="center-wrap">
                        <Link to={itemone.btnlink} className="btn-a">
                            <div className="button">
                                {itemone.BtnName}
                                <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" /></div>
                        </Link>
                    </div> */}
                </div>
            </div>
            
        ));
        //End ItemOne Loop
        return (
        <React.Fragment>
            <div role="tabpanel" className="tab-pane fade show active" id="monthly">
                <div className="pricing-container margin-top-60">
                    {itemonedata}           
                </div>
            </div>
        </React.Fragment>
        );
    }
}
ItemOne.PropsTypes = {
    itemonesData: PropTypes.array
};
ItemOne.defaultProps = {
    itemonesData: [
        {
            planName: "ONE NETWORK INCLUDES",
            className: "plan",
            //description: "Free of charge one standard listing active for 30 days",
            FeaturedClass: "",
            Featured: "",
            price: "",
            content1: "5 social posts per month",
            content2: "3 custom images per month",   
            content3: "1 boosted posts",
            content4: "$500",
            content5: "per month",
            content6: "$5000 - $8000",
            // BtnName: "Purchase Now",
            // btnlink: "/#0 ",
        },
        {
            planName: "TWO NETWORKS INCLUDE",
            className: "plan",
            //description: "Free of charge one standard listing active for 30 days",
            FeaturedClass: "",
            Featured: "",
            price: "",
            content1: "10 social posts per month",
            content2: "4 custom images per month",   
            content3: "2 boosted posts",
            content4: "$900",
            content5: "per month",
            content6: "$5000 - $8000",
        },
        {
            planName: "THREE NETWORKS INCLUDE",
            className: "plan featured",
            //description: "Free of charge one standard listing active for 30 days",
            FeaturedClass: "listing-badges",
            Featured: "Featured",
            price: "",
            content1: "20 social posts per month",
            content2: "5 custom images per month",   
            content3: "3 boosted posts",
            content4: "$1,500",
            content5: "per month",
            content6: "$5000 - $8000",
        },
        {
            planName: "FOUR NETWORKS INCLUDE",
            className: "plan",
            //description: "Free of charge one standard listing active for 30 days",
            FeaturedClass: "",
            Featured: "",
            price: "",
            content1: "30 social posts per month",
            content2: "6 custom images per month",   
            content3: "4  boosted posts",
            content4: "$1,800",
            content5: "per month",
            content6: "$5000 - $8000",
        },
        {
            planName: "FIVE NETWORK INCLUDES",
            className: "plan",
            //description: "Free of charge one standard listing active for 30 days",
            FeaturedClass: "",
            Featured: "",
            price: "",
            content1: "50 social posts per month",
            content2: "7 custom images per month",   
            content3: "5 boosted posts",
            content4: "$2,200",
            content5: "per month",
            content6: "$5000 - $8000",
        },
        
    ]
};
export default ItemOne;
