import React, { Component } from 'react';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';
import erp from "../../../assets/img/mob.png";
import "react-modal-video/css/modal-video.min.css";


 
class Process extends Component {
   
        render() {
        return (
            <React.Fragment>
        <section id="process" className="cta-areacost ">
        <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div className="col-sm-4">   
                                
                                <div className="am">
                                     
                                     <h4 > <strong Style="font-size: 30px;">1.</strong><strong Style="color: black;"> Define your goal</strong></h4>
                                      <h4  Style="color: black; font-size: 16px;"> What are your specific goals for this website or App? What exactly are you trying to achieve through this platform? Knowing your goals will help us to customize and optimize the design for your user's desired experience. </h4>
                                      <br/>
                                      
                                      <h4 > <strong  Style="font-size: 30px;">2. </strong> <strong Style="color: black;"> We get to know your audience </strong></h4>
                                     <h4  Style="color: black; font-size: 16px;"> Who are you trying to reach? What are these people like? We work hard to geet a deep psycological and behavioral understanding of your customers. This lets us know yourb audience's likes and dislike.  </h4>
                                     <h4 Style="color: black; font-size: 16px;"> This informations then helps us to plan for who you're targeting and how to best design to reach them. Helpful tip: create a fictional character who represents the behaviors and attitude of your ideal customer.</h4>
                                     <br/>
                                     
                                     <h4 > <strong Style="font-size: 30px;">3. </strong> <strong Style="color: black;"> We ask questions</strong></h4>
                                     <h4  Style="color: black; font-size: 16px;"> "There are no stupid questions." Good user experience design will never come if you don't ask questions and learn about the task at hand. Our ability to gain a deep understanding of every project we take on is what sets us apart.  </h4>
                                     <br/>
                                     </div>
                        </div>

                                
                                     <div className="col-sm-4">
                                     <div className="imgBx">
                                   <img Style=" margin-left:-5px;"className="aya12" src={erp} alt="images" />
                             </div>
                                </div>
                                      <div className="col-sm-4">
                                      <div className="am">
                                     
                                     <h4 > <strong Style="font-size: 30px;">4.</strong><strong Style="color: black;"> We sketch and re-sketch</strong></h4>
                                      <h4 Style="color: black; font-size: 16px;"> Our First design will almost never be our last. it takes multiple passes throygh a design to fully assess it. The beauty in a well-designed user interface  or experience is in the details. That's why we make multiple sketches, edits, and adjustments to craft the ideal design for your needs.   </h4>
                                      <br/>
                                     
                                      <h4 >  <strong Style="font-size: 30px;">5.</strong><strong Style="color: black;"> We Love user testing </strong> </h4>
                                     <h4 Style="color: black; font-size: 16px;"> The only true way to know the effectiveness of your UX design is through authenticuser testing. We're not afraid to try different things and see what engage your users.  </h4>
                                     <br/>
                                     
                                     <h4 ><strong Style="font-size: 30px;">6. </strong> <strong Style="color: black;">We polish the visuals</strong></h4>
                                     <h4 Style="color: black; font-size: 16px;"> We make sure that every single image, every font, every color, is crisp and clean. Every visual you see from icons to layouts is perfected and designed to fit your particular goals. No small details is overlooked.  </h4>
                                     <br/>
                                     
                                     <h4 > <strong Style="font-size: 30px;">7. </strong> <strong Style="color: black;">Real user feedback and design</strong></h4>
                                     <h4 Style="color: black; font-size: 16px;"> After the user experience is fully designed, developed, and in front of users, We can get real feedback. This is real feedback influences real, effective re-design. This way, after all of the steps, you get the best user experience possible.   </h4>
                                     </div>
                                </div>
                    </div>
        {/* <div>
        <img src={process} alt="Process_Mobile" className ="process-Mobile-osama" />
            </div> */}
        </section>
        </React.Fragment>
        );

    }

}
Process.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
   
};
Process.defaultProps = {
    SectionbgTitle: "The Process",
    sectionTitle: "The Process",
};

export default Process;