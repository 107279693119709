import React, { Component } from "react";
import PropTypes from "prop-types";
import Icofont from 'react-icofont';
// import { Link } from 'react-router-dom';
import { NavLink } from "reactstrap";

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <p className="copyright">{this.props.copyrightText}</p>
                            </div>
                            <div className="col-md-7">
                                <div className="social-icons bottom">
                                    <ul className="list-inline">
                                        <li>{this.props.socialTitle} </li>
                                        <li><NavLink href="https://www.facebook.com/itlandservices/" target="_blank" className="nav-link" ><Icofont icon="icofont-facebook"/></NavLink></li>
                                        <li><NavLink href="https://www.instagram.com/IT.LAND.Group/" target="_blank" className="nav-link"><Icofont icon="icofont-instagram"/></NavLink></li>
                                        <li><NavLink href="https://www.linkedin.com/company/it-land-group/" target="_blank" className="nav-link"><Icofont icon="icofont-linkedin"/></NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}
//Props Types
Footer.propTypes = {
    copyrightText: PropTypes.string,
    FacebookLink: PropTypes.string,
    TwitterLink: PropTypes.string,
    InstagramLink: PropTypes.string,
    linkedinLink: PropTypes.string,
};

//Default Props
Footer.defaultProps = {
    copyrightText: "2020 IT LAND © All Rights Reserved.",
    socialTitle: "Follow Us On:",
    FacebookLink: "/https://www.facebook.com/itlandservices/",
    TwitterLink: "/#0",
    InstagramLink: "/https://www.instagram.com/IT.LAND.Group/",
    linkedinLink: "/https://www.linkedin.com/company/it-land-group/",
};
export default Footer;
 