import React, { Component } from 'react';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';
// import Icofont from 'react-icofont';
// import { Link } from 'react-router-dom';
import tick from "../../../assets/img/tick.png";
import DigitalTips from "../../../assets/img/digital2.png";
import tick2 from '../../../assets/img/tick2.png';
import "react-modal-video/css/modal-video.min.css";


class About extends Component {
    render() {
    return (
        <React.Fragment>
    <section id="offers" className="cta-areacost cta-bg-imgcost">
    <div className="diplay-table">
        <div className="display-table-cell">
            <div className="container">
                <ScrollAnimation animateIn="fadeInUp">
                <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                   <div> 
                        <h2><strong>Social media advertising </strong>benefits </h2>
                       </div>
                   <hr/>
                        <div className="col-sm-6 col-md-7 col-lg-7">
                            <div className="cta-infocost">
                                 <div class="wpb_wrapper">
                                 
                                        <article class="icon-box5">
                
                                             <h4><img src={tick} alt =""  Style="width: 3%;" /> Build brand awareness</h4>
                                            </article>
                                        <article class="icon-box5">
                                            {/* <i class="sl-check" Style=" color:#00c2e5;"></i> */}
                                            <h4><img src={tick} alt =""  Style="width: 3%;" /> Reach new customers</h4>
                                        </article>
                                        <article class="icon-box5">
                                            {/* <i class="sl-check" Style=" color:#00c2e5;"></i> */}
                                            <h4><img src={tick} alt =""  Style="width: 3%;" /> Improve customer satisfaction</h4>
                                        </article>
                                        <article class="icon-box5">
                                            {/* <i class="sl-check" Style=" color:#00c2e5;"></i> */}
                                            <h4><img src={tick} alt =""  Style="width: 3%;" /> Improve search engine rankings</h4>
                                        </article>
                                    
                                  </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-7 col-lg-5 ">
                        <div className="cta-infocost">
                                        <div class="wpb_wrapper">
                                                <article class="icon-box5">
                                                    <h4><img src={tick} alt =""  Style="width: 3%;" /> Increase brand loyalty</h4>
                                                    </article>
                                                <article class="icon-box5">
                                                    {/* <i class="sl-check" Style=" color:#00c2e5;"></i> */}
                                                    <h4><img src={tick} alt =""  Style="width: 3%;" /> Generate high quality leads and sales </h4>
                                                </article>
                                                <article class="icon-box5">
                                                    {/* <i class="sl-check" Style=" color:#00c2e5;"></i> */}
                                                    <h4><img src={tick} alt =""  Style="width: 3%;" /> More inbound traffic to website</h4>
                                                </article>
                                                <article class="icon-box5">
                                                    {/* <i class="sl-check" Style=" color:#00c2e5;"></i> */}
                                                    <h4><img src={tick} alt =""  Style="width: 3%;" /> Cost-effective</h4>
                                                </article>
                                        </div>
                                    </div>
                        </div>
                    </div>


                    {/* tips  */}

                    <div className="row">
                   <div> 
                       <h2>Tips By <strong>Platform</strong></h2>
                        <p>The best approach for business on social media varies on platform, so we have created resources tailored to the ones you want to use</p>
                       </div>
                   <hr/>
                        <div className="col-sm-6 col-md-7 col-lg-7">
                            <div className="cta-infocost">
                                 <div class="wpb_wrapper">
                                 
                                        <img src={DigitalTips} alt="Digital-marketing-tips" Style="width: 45%; margin-top: -31px;" />
                                    
                                  </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-7 col-lg-5 ">
                        <div className="cta-infocost">
                                        <div class="wpb_wrapper">
                                                <article class="icon-box5">
                                                    <h4><img src={tick} alt =""  Style="width: 3%;" /> Facebook</h4>
                                                    </article>
                                                <article class="icon-box5">
                                                    {/* <i class="sl-check" Style=" color:#00c2e5;"></i> */}
                                                    <h4><img src={tick} alt =""  Style="width: 3%;" /> Twitter</h4>
                                                </article>
                                                <article class="icon-box5">
                                                    {/* <i class="sl-check" Style=" color:#00c2e5;"></i> */}
                                                    <h4><img src={tick} alt =""  Style="width: 3%;" /> Instagram</h4>
                                                </article>
                                                <article class="icon-box5">
                                                    {/* <i class="sl-check" Style=" color:#00c2e5;"></i> */}
                                                    <h4><img src={tick} alt =""  Style="width: 3%;" /> LinkedIn</h4>
                                                </article>
                                                <article class="icon-box5">
                                                    {/* <i class="sl-check" Style=" color:#00c2e5;"></i> */}
                                                    <h4><img src={tick} alt =""  Style="width: 3%;" /> Pinterest</h4>
                                                </article>
                                                <article class="icon-box5">
                                                    {/* <i class="sl-check" Style=" color:#00c2e5;"></i> */}
                                                    <h4><img src={tick} alt =""  Style="width: 3%;" /> Youtube</h4>
                                                </article>
                                        </div>
                                    </div>
                        </div>
                    </div>
                    {/* ---------- */}
                    <div className="row">
                   <div> 
                       <h2>What companies should use <strong>Facebook ad management services</strong>?</h2>
                       </div>
                   <hr/>
                        <div className="col-sm-6 col-md-7 col-lg-7">
                            <div className="cta-infocost">
                                 <div class="wpb_wrapper">
                                 
                                        <article class="icon-box5">
                
                                             <h4><img src={tick} alt =""  Style="width: 3%;" /> Grow brand awareness</h4>
                                            </article>
                                        <article class="icon-box5">
                                            {/* <i class="sl-check" Style=" color:#00c2e5;"></i> */}
                                            <h4><img src={tick} alt =""  Style="width: 3%;" /> Save time on marketing your business</h4>
                                        </article>
                                        <article class="icon-box5">
                                            {/* <i class="sl-check" Style=" color:#00c2e5;"></i> */}
                                            <h4><img src={tick} alt =""  Style="width: 3%;" /> Scale compaigns and measure results</h4>
                                        </article>
                                        <article class="icon-box5">
                                            {/* <i class="sl-check" Style=" color:#00c2e5;"></i> */}
                                            <h4><img src={tick} alt =""  Style="width: 3%;" /> Get expert results</h4>
                                        </article>
                                  </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-7 col-lg-5 ">
                        <div className="cta-infocost">
                                        <div class="wpb_wrapper">
                                                <article class="icon-box5">
                                                    <h4><img src={tick} alt =""  Style="width: 3%;" /> Complete with competitors</h4>
                                                    </article>
                                                <article class="icon-box5">
                                                    {/* <i class="sl-check" Style=" color:#00c2e5;"></i> */}
                                                    <h4><img src={tick} alt =""  Style="width: 3%;" /> Optimize ads</h4>
                                                </article>
                                                <article class="icon-box5">
                                                    {/* <i class="sl-check" Style=" color:#00c2e5;"></i> */}
                                                    <h4><img src={tick} alt =""  Style="width: 3%;" /> Create successful ad compaigns</h4>
                                                </article>
                                                <article class="icon-box5">
                                                    {/* <i class="sl-check" Style=" color:#00c2e5;"></i> */}
                                                    <h4><img src={tick} alt =""  Style="width: 3%;" /> Resolve plateaus in Facebook ad</h4>
                                                    <h4><img src={tick2} alt =""  Style="width: 3%;" /> performance</h4>
                                                </article>
                                        </div>
                                    </div>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    </div>
</section>
        </React.Fragment>
    );
  }
}
//Props Types
About.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Props Types
About.propTypes = {
    Title: PropTypes.string,
    Content: PropTypes.string,
    Content1: PropTypes.string,
    Content2: PropTypes.string,
    Content3: PropTypes.string,
    Content4: PropTypes.string,
    Content5: PropTypes.string,
    Content6: PropTypes.string,
    Content7: PropTypes.string,
    Content8: PropTypes.string,
    Content9: PropTypes.string,
    Content10: PropTypes.string,
    Content11: PropTypes.string,
    Content12: PropTypes.string,
    Content13: PropTypes.string,
    Content14: PropTypes.string,
   // BtnLink: PropTypes.string,
  //  BtnName: PropTypes.string,
    //Image: PropTypes.string,
    
};

//Default Props
About.defaultProps = {
    SectionbgTitle: "Offers",
    sectionTitle: "What We Offer!",
    Title: "We offer best in class service for your needs",
    Content: "	Design and Development custom web solutions.",
    Content1: "Wordpress site development.",
    Content2: "On-site & off-site SEO services.",
    Content3: "Responsive web design.",
    Content4: "User-friendly content management system- CMS. ",
    Content5: "Redesign and maintenance services.",
    Content6: "Payment processor integrations.",
    Content7: "Corporate web based solutions.",
    Content8: "	Custom business website solutions.",
    Content9: "Social media integration.",
    Content10: "We use the most popular e-commerce platforms for an online store.",
    Content11: "Programming languages: HTML5, CSS3, PHP, ASP.NET, JavaScript. ",
    Content12: "Web design services for small business. ",
    Content13: "User experience (UX) design.",
    Content14: "Re-Design and maintenance services. ",
    

  // BtnLink: "/#0",
  // BtnName: "PURCHASE NOW",
   // Image: require("../../../assets/img/lap.png"),
};

export default About;
