import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';

class Services extends Component {
  render() {
      //Service loop start
      const servicedata = this.props.servicesData.map((service, index) => (
        <div className="col-md-6 col-lg-4 text-center" key={index}>
            <div className="service-item">
                <div className="glyph">
                    <Icofont icon={service.icon} />
                </div>
                <h3>{service.heading}</h3>
                <p>{service.description}</p>
            </div>
        </div>
    ));
    //Service loop END
    return (
        <React.Fragment>
            <section id="services" className="services ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    {/* <p>{this.props.sectionDescription}</p> */}
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                        {servicedata}
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
  }
}

//Props Types
Services.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Services.defaultProps = {
    SectionbgTitle: "Services",
    sectionTitle: "Services",
    sectionDescription:
        "Capture more leads with a conversion-focused website custom-designed to help you achieve your business goals and objectives.",

    servicesData: [
        {
            icon: "icofont-credit-card",
            heading: "Accept Credit Cards",
            description:
                "We can help in the development and implementation of your online credit card processing systems. Our systems work with MasterCard, Visa, PayPal, and Stripe to offer multiple payment options to your customers."
        },
        {
            icon: "icofont-tracking",
            heading: "Monitoring & Tracking",
            description:
                "You need shipment and customer data to offer a great customer experience. Our eCommerce platforms can help with the monitoring and tracking of shipments, inventory, and customers. "
        },
        {
            icon: "icofont-safety",
            heading: "Safe & Secure",
            description:
                "Platforms are securely hosted and deployed to ensure the protection of customer and company information. We utilize SSL certificates, secure backups for relevant information, and many other best practices for digital security."
        },
        {
            icon: "icofont-stock-mobile",
            heading: "Mobile Commerce Friendly",
            description:
                "In 2020, about 85% of smartphone users have purchased in the last six months. Having a mobile-friendly e-commerce site can help boost your sales numbers."
        },
        {
            icon: "icofont-attachment",
            heading: "Multiple Languages",
            description:
                "The world is becoming more and more globalized. Having a site that offers multiple languages is essential to reach potential customers anywhere they may be."
        },
        {
            icon: "icofont-repair",
            heading: "Reliable Technical Support",
            description:
                "Anything you need, we’re here. You can always count on us to help with the upkeep and maintenance of your online shop."
        },
    ]
};

export default Services;
