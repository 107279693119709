import React, { Component } from 'react';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';

import tick from "../../../assets/img/tick.png";

import "react-modal-video/css/modal-video.min.css";

import DigitalTips from "../../../assets/img/SEARCH_ENGINE_MARKETING.jpg";
class Techniques extends Component {
    render() {
    return (
        <React.Fragment>
    <section id="techniques" className="cta-areacost cta-bg-imgcost"> 
    <div className="diplay-table">
        <div className="display-table-cell">
            <div className="container">
                <ScrollAnimation animateIn="fadeInUp">
                <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                        
                   <div> 
                       <h2>{this.props.Title}</h2>
                      
                   </div>
                   <hr/>
                        <div className="col-sm-12">
                            <div className="cta-infocost">

                                     <ul>
                                            <li>
                                                <p Style="color: black; font-weight:normal; font-size: 15px;"><img src={tick} alt =""  Style="width: 2%;" />
                                                <strong> Paid Search:</strong>Paid search means pay-per-click (PPC) and cost per click (CPC)advertising. It is typically refers to the “Sponsored Result” on the top or side of a search engine results pages. This is paid advertising and you only pay when your ad is clicked. You can tailor PPC ads to appear, when specific search keyword terms are entered, creating ads that are targeted to a particular audience.</p>
                                            </li>
                                            <li>
                                                <p Style="color: black; font-weight:normal; font-size: 15px;"><img src={tick} alt =""  Style="width: 2%;" />
                                                <strong> Pay-Per-Click:</strong>(PPC)Ads are flexible, visible and most effective for many different types of organizations. They are also contextual advertisements—ads that appear when a person is searching for a particular keyword or key terms.</p>
                                            </li>
                                            <li>
                                                <p Style="color: black; font-weight:normal; font-size: 15px;"><img src={tick} alt =""  Style="width: 2%;" />
                                                <strong> Search Engine Optimization:</strong>Search Engine Optimization (SEO)is the process of optimizing the content in ON-Page Optimizationand OFF-Page Optimization. It is technical set-up and reach of your website so that your pages appear at the top of a search engine result for a specific set of keyword terms.</p>
                                            </li>
                                            <li>
                                                <p Style="color: black; font-weight:normal; font-size: 15px;"><img src={tick} alt =""  Style="width: 2%;" />
                                                <strong> Search Engine Optimizationis:</strong>almost viewed as a set of best practices for good digital marketing. It enforces the need for well-constructed, easy to use website valuables, engaging contents, credibility for other websites and individuals to refer you by linking to your website or mentioning it in social media posts.</p>
                                            </li>
                                            <li>
                                                <p Style="color: black; font-weight:normal; font-size: 15px;"><img src={tick} alt =""  Style="width: 2%;" />
                                                <strong> Content Marketing:“Content is king?”</strong>is the expression which suits best for content marketing. Excellent content is the fuel that drives your digital marketing activities:</p>
                                                <div class="col-lg-12 col-md-6">
                                                    <div class="numberlist">
                                                            <ol>
                                                                <li>It is a key pillar of modern SEO techniques.</li>
                                                                <li>It helps you get noticed on Search engine results and social media.</li>
                                                                <li>It gives you something of values to offer customers in emails and paid search advertisement.</li>
                                                            </ol>
                                                    </div>
                                                    <p Style="color: black; font-weight:normal; font-size: 15px;">Creating effective content that is not promotional in nature, but instead educates and inspires, is tough but well worth the effort. Offering informative content that is relevant to your audience helps you and them to see you as a valuable source of information. Related and Resourceful content makes it less likely that they will tune you out.</p>
                                                </div>
                                            </li>
                                            <li>
                                                <p Style="color: black; font-weight:normal; font-size: 15px;"><img src={tick} alt =""  Style="width: 2%;" />
                                                <strong> Social Media Marketing :</strong>Social Media Optimization (SMO)is the process of monitoring and delivering products and service related content on Social media and engaging users on websites. Now days People aren’t just watching videos and posting selfies on social media these days. Many people are on social networks to discover, research and to educate themselves about brands before engaging with that organization.</p>
                                            </li>
                                            <li>
                                                <p Style="color: black; font-weight:normal; font-size: 15px;"><img src={tick} alt =""  Style="width: 2%;" />
                                                <strong> Email Marketing :</strong>Email marketingis a way of advertisement, which helps to connect with your audience to promote your brand and increase sales. We can do a lot of things with emails, like sell products, share some news or deliver product offers to users. Email marketing still the quickest and most direct way to reach customers with critical information because consumers are very attached to their emails. A Successful email campaigns engage people, relevant, informative and entertaining. For successful email marketing, email should satisfy these five core attributes:</p>
                                                <div class="col-lg-12 col-md-6">
                                                        <div class="numberlist">
                                                                <ol>
                                                                    <li>Trustworthy.</li>
                                                                    <li>Relevant.</li>
                                                                    <li>Conversational.</li>
                                                                    <li>Relevant.</li>
                                                                    <li>Be Coordinated Across Channels.</li>
                                                                    <li>Strategic.</li>
                                                                </ol>
                                                        </div>
                                                </div>
                                            </li>
                                            <li>
                                                <p Style="color: black; font-weight:normal; font-size: 15px;"><img src={tick} alt =""  Style="width: 2%;" />
                                                <strong >Mobile Marketing :</strong>Mobile marketingthrough SMS and MMS to in-app marketing, there are many ways to market on mobile devices, so finding the right method for your particular business is key. Beyond the mechanisms to deliver your message, you also need to think about coordination of your marketing across digital channels and make sure mobile is a part of that mix.</p>
                                            </li>
                                            <li>
                                                <p Style="color: black; font-weight:normal; font-size: 15px;"><img src={tick} alt =""  Style="width: 2%;" />
                                                <strong> Marketing Automation :</strong>Marketing automationis an integral platform that ties all of your digital marketing together. Marketing automation software streamlines, automates marketing tasks and workflows. Mainly, it measures the results and ROI of your digital campaigns, helps to grow revenue faster.</p>
                                                <p Style="color: black; font-weight:normal; font-size: 15px;">
                                                After using it effectively, marketing automation will help you gain much-needed insight into which programs are working and which are not. It will givethe metrics needed to speak confidently about digital marketing’s impact at the bottom line.</p>
                                            </li>


                                      </ul>
                            </div>

                        </div>
                      
                    </div>
               
               
                    <div className="row">
                   <div> 
                       <h2><strong>Pay-Per-Click </strong>search engine advertising (PPC)</h2>
                       <h4 Style="margin-left: 44px;">64.6% of people click on <a href="https://ads.google.com/" target="_blank" rel="noopener noreferrer">Google Ads</a> when they are looking for a service or product online.</h4>
                       </div>
                   <hr/>
                        <div className="col-sm-6 col-md-7 col-lg-7">
                            <div className="cta-infocost">
                                 <div class="wpb_wrapper">
                                 
                                        <img src={DigitalTips} alt="Digital-marketing-tips" Style="/* width: 45%; */margin-top: 83px;height: auto;/* max-width: 122%; */width: 80%;"/>
                                    
                                  </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-7 col-lg-5 ">
                        <div className="cta-infocost">
                                        <div class="wpb_wrapper">
                                        <p Style="margin-top: 35px;font-size: 17px;">Now more than ever, it’s important for businesses to invest in pay-per-click search engine advertising, to strengthen their digital presence. Customers use the internet to search for products and services, and if your business isn’t showing up in search engine results, they won’t find you. With more and more companies understanding the importance of ranking highly on search results pages, the PPC advertising landscape has become more competitive than ever. This can make it difficult to navigate without expert help.</p>
                                        <p Style="margin-top: 35px;font-size: 17px;">Our digital campaign experts can create the right strategy to drive relevant traffic to your website and convert them into leads or sales through optimized landing pages. Leveraging years of experience running Google and Bing search campaigns, we cut through the technical fluff and implement strategies that boost bottom-line results.</p>
                                        </div>
                                    </div>
                        </div>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    </div>
</section>
        </React.Fragment>
    );
  }
}
//Props Types
Techniques.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Props Types
Techniques.propTypes = {
    Title: PropTypes.string,
    Content: PropTypes.string,
    Content1: PropTypes.string,
    Content2: PropTypes.string,
    Content3: PropTypes.string,
    
   // BtnLink: PropTypes.string,
  //  BtnName: PropTypes.string,
    //Image: PropTypes.string,
    
};

//Default Props
Techniques.defaultProps = {
    SectionbgTitle: "Techniques",
    sectionTitle: "Our Techniques",
    Title: "Digital marketing includes following techniques for advertising products and service:",
    Content: "Build a mobile app that makes the most of Apple’s platform, focusing on outstanding performance and a polished UI.",
    Content1: "Embrace the openness of Android to create a lightning-fast and sleek native app that serves a myriad of devices.",
    Content2: "Cut your development and maintenance costs with cross-platform apps that work flawlessly across iOS and Android.",
    Content3: "Which way do you go Native or Cross-Platform? If you haven’t made up your mind yet, Our Mobile experts will help you figure out what works best for your product. Let’s set up a call and talk about your needs in detail.",
   
};

export default Techniques;