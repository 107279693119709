import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';

class Services extends Component {
  render() {
      //Service loop start
      const servicedata = this.props.servicesData.map((service, index) => (
        <div className="col-md-6 col-lg-4 text-center" key={index}>
            <div className="service-item">
                <div className="glyph">
                    <Icofont icon={service.icon} />
                </div>
                <h3>{service.heading}</h3>
                <p>{service.description}</p>
            </div>
        </div>
    ));
    //Service loop END
    return (
        <React.Fragment>
            <section id="services" className="services ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                        {servicedata}
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
  }
}

//Props Types
Services.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Services.defaultProps = {
    SectionbgTitle: "Services",
    sectionTitle: "Services",
    sectionDescription:
        " Smart phones are the 24-hour companion of the modern cultured person. One billion smart phones are there now, which will gradually increase.",

    servicesData: [
        {
            icon: "icofont-brand-android-robot",
            heading: " Native App Development",
            description:
                "Product companies have a clear inclination towards native app development to deliver the best device fidelity, user experience and hardware specific features."
        },
        {
            icon: "icofont-brand-apple",
            heading: "Enterprise Mobility Solutions",
            description:
                "Enterprise mobility solutions, being the most disruptive technological innovation today, setting mobile driven processes and aligning workforce in effective way."
        },
        {
            icon: "icofont-responsive",
            heading: "Mobile App Monetization",
            description:
                "With $120 billion app related revenues expected by 2020, freemium apps leading the stock but in-app advertising is taking monetization to next level."
        },
        {
            icon: "icofont-smart-phone",
            heading: " Cross Platform App Development",
            description:
                "Enterprise-to-consumer apps are fueling demand of cross platform app development to rapidly develop and deploy mobile Applications."
        },
        {
            icon: "icofont-dashboard",
            heading: "Mobile App Strategy",
            description:
                "Mobile app strategy begins with the idea of interaction. The ultimate goal is to build the app that provides customers with exactly, what they want."
        },
        {
            icon: "icofont-arrow-right",
            heading: "Best User Experience",
            description:
                "User experience is way more than the way, app looks in terms of user interfaces. Its about providing WOW feeling to users, while they navigate."
        },
    ]
};

export default Services;
