import React, { Component } from 'react';

import PropTypes from "prop-types";
// import tick from '../../../../assets/img/tick.png';
// import tick2 from '../../../../assets/img/tick2.png';
import img from "../../../../assets/img/Comp-22.gif";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
class BannerThree extends Component {
    render() {
        return (
            <React.Fragment>
                <div id="home" className="home-video-area">
            <div class="carousel-wrapper">
            <Carousel showArrows={true} showIndicators={false} showThumbs={false} showStatus={false} autoPlay={true} >
                <div>
                <img src={img} alt="MISSING JPG"/> 
                </div>
              
            </Carousel>
        </div>
                
                    <div className="video-text-area">
                        <div className="container">
                            {/* <div className="row">
                                <div className="col-lg-12"> */}
                                    
                                    <p
                                    className=" animated fadeInDown slow opacityOne OsamaPar ">
                                          {this.props.Title1}
                                        </p>
                                    
                                             <ul Style="    font-size: 20px;">
                                                <li><p>We Create Your iOS & Android Mobile Applications with Our World Class Developers.</p></li>
                                                <li><p>Responsive design optimized for all browsers and devices.</p></li>
                                                <li> <p>New content a breeze.</p></li>
                                             </ul>
                                    
    
                                {/* </div>
                            </div> */}
                        </div>
                    </div>
                </div>    
            </React.Fragment>
        );
    }
}
//Props Types
BannerThree.propTypes = {
    Title1: PropTypes.string,
    Content: PropTypes.string,
    Content3: PropTypes.string,
    Content1: PropTypes.string,
    Content2: PropTypes.string,
    Content4: PropTypes.string,
  
};

//Default Props
BannerThree.defaultProps = {
    
    Title1:"WE LOVE BUILDING AWESOME MOBILE APPS !" ,
   Content: "We Create Your iOS & Android Mobile Applications with Our World Class",
   Content3: " Developers.",
   Content1: "Responsive design optimized for all browsers and devices .",
   Content2: "User-friendly Content Management System (CMS) makes editing & adding ",
   Content4: " new content a breeze.",
};
export default BannerThree;
