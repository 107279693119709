import React, { Component } from "react";

import PropTypes from "prop-types";
import ScrollAnimation from "react-animate-on-scroll";


import workOne from "../../../assets/img/work-1.gif";
import workTwo from "../../../assets/img/work-2.gif";
import workThree from "../../../assets/img/work-3.gif";
import workFour from "../../../assets/img/work-4.gif";
import workFive from "../../../assets/img/work-5.gif";
import workSix from "../../../assets/img/work-6.gif";
// import workSeven from "../../../assets/img/work-7.gif";


class Works extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          photoIndex: 0,
          isOpen: false
        };
      }

    render() {
        // const { photoIndex, isOpen } = this.state;
        return (
            <React.Fragment>
                <section id="works" className="our-works ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2 text-center">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="section-title">
                                        <h2>{this.props.sectionTitle}</h2>
                                        <p>{this.props.sectionDescription}</p>
                                        <span className="section-title-bg">
                                            {this.props.SectionbgTitle}
                                        </span>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <div class="cards-list-work">
                    <div class="card-work 1">
                            <div class="card_image-work">
                                <img src={workOne} alt="ikea-corporation" />
                                </div>
                           
                                
                          
                            </div>
                            <div class="card-work 2">
                            <div class="card_image-work">
                                <img src={workTwo} alt="open-corpdata" />
                                </div>
                            </div>

                            <div class="card-work 3">
                            <div class="card_image-work">
                                <img src={workThree} alt=".." />
                            </div>
                          
                            </div>
                            
                            <div class="card-work 4">
                            <div class="card_image-work">
                                <img src={workFour} alt="GrandRiverHospitals"/>
                                </div>
                         
                            </div>
                            <div class="card-work 5">
                            <div class="card_image-work">
                                <img src={workFive} alt=".."/>
                                </div>
                         
                            </div>
                            <div class="card-work 6">
                            <div class="card_image-work">
                                <img src={workSix} alt=".."/>
                                </div>
                         
                            </div>
                           
                            

                            </div>

                    
                </section>
            </React.Fragment>
        );
    }
}
//Props Types
Works.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
};

//Default Props
Works.defaultProps = {
    SectionbgTitle: "Clients",
    sectionTitle: "Our Clients",
    sectionDescription:
        "It is a tale as old as time—We perceive less risk and are more apt to Buy, when we see our peers and those we respect have already taken the plunge.",
};

export default Works;
