import React, { Component } from 'react';
// import Icofont from 'react-icofont';
// import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
// import tick from '../../../../assets/img/tick.png';
// import videoaya from "../../assets/video.wmv"
import img from "../../../../assets/img/Ec-2.gif";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
class BannerThree extends Component {
    render() {
        return (
            <React.Fragment>
                <div id="home" className="home-video-area">
                {/* <video id="bgvid" loop autoPlay muted>
                    <source src="http://taxify.co/wp-content/uploads/2014/06/file.mp4" type="video/mp4" />
                </video> */}
                 
            <div class="carousel-wrapper">
            <Carousel showArrows={true} showIndicators={false} showThumbs={false} showStatus={false} autoPlay={true} >
                <div>
                <img src={img} alt="MISSING JPG"/> 
                </div>
                {/* <div>
                <img src={img} alt="Hong Kong" />
                </div>
                <div>
                <img src={img} alt="Hong Kong" />
                </div> */}
            </Carousel>
        </div>
                
                    <div className="video-text-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                   
                                    <div className="container">
                                    {/* <h1
                                        className="animated fadeInDown slow opacityOne" Style="margin-top: 54px;">
                                          eCommerce Web Design & Website Development
                                    </h1> */}
                                    </div>
                                    <div className="row">
                                    <p className=" animated fadeInDown slow opacityOne" Style="font-size: 17px; margin-top: 80px;
}">Everybody knows that retail stores are good business, no matter what you sell; there will always be people near you willing to buy your products or services. But a retail store is limited to its geographical location. if you want to go global, there are no limits to the internet. <span class="sc-1_4_4-link__content"><br/> <br/>  We can help you design and develop end-to-end e-commerce solutions to streamline communication, automate workflow. manage high volume transications, user management and authentication, data and content management, shopping cart applications, and payment gateway integration</span></p>
                                     </div>
                                    <p
                                        className="animated fadeInDown slow opacityOne aya11">
                                       
                                        
                                    </p>
                                    {/* <div className="center-wrap">
                                        <Link to={this.props.BtnLink} className="btn-a">
                                            <div className="button">
                                                {this.props.BtnName}
                                                <Icofont icon="icofont-long-arrow-right" />
                                            <div className="mask" /></div>
                                        </Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </React.Fragment>
        );
    }
}
//Props Types
BannerThree.propTypes = {
    Title: PropTypes.string,
    Title1: PropTypes.string,
    TopTitle: PropTypes.string,
   
    Content: PropTypes.string,
    Content1: PropTypes.string,
    Content2: PropTypes.string,
    //BtnLink: PropTypes.string,
   // BtnName: PropTypes.string,
};

//Default Props
BannerThree.defaultProps = {
    //TopTitle: "E-commerce Solutions",
    Title:"" ,
   // Title1:"Capture more leads with a conversion-focused website custom-designed to help you achieve your business goals and objectives." ,
   Content: "Everybody knows that retail stores are good business, no matter what you sell; there will always be people near you willing to buy your products or services. But a retail store is limited to its geographical location. if you want to go global, there are no limits to the internet.",
   Content1: "We can help you design and develop end-to-end e-commerce solutions to streamline communication, automate workflow. manage high volume transications, user management and authentication, data and content management, shopping cart applications, and payment gateway integration.  .",
  // Content2: "User-friendly Content Management System (CMS) makes editing & adding new content a breeze.",
   // BtnLink:  "/#",
   // BtnName: "webSiteDevelopment",
};
export default BannerThree;
 