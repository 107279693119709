import React, { Component } from "react";
import PropTypes from "prop-types";


export class ItemOne extends Component {
    render() {
        //Start ItemOne Loop
        const itemonedata = this.props.itemonesData.map((itemone, index) => (
            
            <div className={itemone.className} key={index}>
                <div className={itemone.FeaturedClass}>
                    <span className="featured">{itemone.Featured}</span>
                </div>
                <div className="plan-price">
                    <h3>{itemone.planName}</h3>
                    <span className="value">{itemone.price}</span>
                    {/* <span className="period">{itemone.description}</span> */}
                </div>

                <div className="plan-features">
                    <ul>
                        <li>{itemone.content1}</li>
                        <li>{itemone.content2}</li>
                        <hr />
                        <li>{itemone.content3}</li>
                        <li>{itemone.content4}</li>
                        <hr />
                        <li>{itemone.content5}</li>
                        <li>{itemone.content6}</li>
                        <hr />
                        <li>{itemone.content7}</li>
                        <li>{itemone.content8}</li>
                    </ul>
                    {/* <div className="center-wrap">
                        <Link to={itemone.btnlink} className="btn-a">
                            <div className="button">
                                {itemone.BtnName}
                                <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" /></div>
                        </Link>
                    </div> */}
                </div>
            </div>
            
        ));
        //End ItemOne Loop
        return (
        <React.Fragment>
            <div role="tabpanel" className="tab-pane fade show active" id="monthly">
                <div className="pricing-container margin-top-60">
                    {itemonedata}           
                </div>
            </div>
        </React.Fragment>
        );
    }
}
ItemOne.PropsTypes = {
    itemonesData: PropTypes.array
};
ItemOne.defaultProps = {
    itemonesData: [
        {
            planName: "Basic",
            className: "plan",
            //description: "Free of charge one standard listing active for 30 days",
            FeaturedClass: "",
            Featured: "",
            price: "",
            content1: "1-3 pages",
            content2: "$500   - $2,000",   
            content3: "4-7 pages",
            content4: "$2,500 - $4,000",
            content5: "7-10 pages",
            content6: "$5,000 - $8,000",
            // BtnName: "Purchase Now",
            // btnlink: "/#0 ",
        },
        {
            planName: "Advanced",
           className: "plan featured",
            //description: "One time fee for one listing, highlighted in the search results",
            FeaturedClass: "listing-badges",
            Featured: "Featured",
           // price: "$145",
            content1: "11-15 pages",
            content2: "$8,500 - $11,000",
            content3: "16-20 pages",
            content4: "$12,000 - $18,000",
            content5: "21-25 pages",
            content6: "$19,000 - $25,000",
            //content7: "Enhanced Security",
            // BtnName: "Purchase Now",
            // btnlink: "/#0",
        },
        {
            planName: "Enterprise",
            className: "plan",
           // description: "Monthly subscription for unlimited listings and availability",
            FeaturedClass: "",
            Featured: "",
            //price: "$555",
            content1: "26-30 pages",
            content2: "$26,000 - $32,000",
            content3: "31-40 pages",
            content4: "$32,000 - $60,000",
            content5: "41-50 pages",
            content6: "$61,000 - $100,000",
        },
        
    ]
};
export default ItemOne;
