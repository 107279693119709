import React, { Component } from 'react';
// import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';
// import { Link } from 'react-router-dom';
// import tick from "../../../assets/img/tick.png";

class WelcomeServices extends Component {
    render() {
        return (
            <React.Fragment>
                <section id="welcome" className="cta-areacost cta-bg-imgcost">
                    <div className="diplay-table">
                        <div className="display-table-cell">
                            <div className="container">
                                <ScrollAnimation animateIn="fadeInUp">

                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2 text-center">
                                        <ScrollAnimation animateIn="fadeInUp">
                                            <div className="section-title">
                                                <h2>{this.props.sectionTitle}</h2>
                                                {/* <p>{this.props.sectionDescription}</p> */}
                                                <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                            </div>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                                    <div className="row cost">
                                       < ul>
                                       <li><h1>{this.props.Title1}</h1></li> 
                                       <li><h2>{this.props.Title2}</h2></li> 
                                       <li><h3>{this.props.Title3}</h3></li> 
                                        </ul>
                                 
                                        
                                
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

//Props Types
WelcomeServices.propTypes = {
    Title: PropTypes.string,
    Title1: PropTypes.string,
    Title2: PropTypes.string,
    Title3: PropTypes.string,
    Content: PropTypes.string,
    Content1: PropTypes.string,
    Content2: PropTypes.string,
    Content3: PropTypes.string,
    Content4: PropTypes.string,
    Content5: PropTypes.string,
    Content6: PropTypes.string,
    Content7: PropTypes.string,
    // BtnLink: PropTypes.string,
    // BtnName: PropTypes.string,
   // Image: PropTypes.string,
    
};

//Default Props
WelcomeServices.defaultProps = {
    SectionbgTitle: "Cost",
    sectionTitle: "Our Cost",
    Title2: "How Much Should Digital Marketing Cost in 2020?",
    Title3: "Transparency matters when it comes to Facebook advertising services. That’s why we publish our prices and deliverables online, making it easy for you to see if IT LAND is the perfect partner for your growing business. ",
    Content: "	How do you want your website to look?",
    Content1: "How many pages do you need?",
    Content2: "What platform will your website be on? (Wordpress, Magento, Shopify, Onveos, etc.)",
    Content3: "Who will put your content into the website (You agency or will you?)",
    Content4: "Do you need advanced CMS capabilities?",
    Content5: "Will you website integrate with other Softwares?",
    Content6: "Do you need to process payments online? (E-Commerce)",
    Content7: "Do you have custom functionality that's needed?",
//    BtnLink: "/#0",
//    BtnName: "PURCHASE NOW",
   // Image: require("../../../assets/img/lap.png"),
};
export default WelcomeServices;
