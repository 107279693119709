import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel3";
import Icofont from "react-icofont";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import VisibilitySensor from "react-visibility-sensor";

class BannerOne extends Component {
    render() {
        //BannerOne loop start
        const banneronedata = this.props.banneronesData.map(
            (bannerone, index) => ( 
                <div Style=" width: 100%; " className={bannerone.BgClass} key={index}>
                    <div className="diplay-table">
                        <div className="display-table-cell">
                            <VisibilitySensor>
                                {({ isVisible }) => (
                                <div className="container">
                                    <div className="row">
                                        <div  className="col-lg-7 col-centered OutsourcingText">
                                            <h4
                                                className={
                                                    isVisible
                                                        ? "animated fadeInDown slow opacityOne"
                                                        : "opacityZero"
                                                }
                                                Style="margin-top:115px; margin-left: 80px;"
                                            >
                                                {bannerone.Title}
                                            </h4>
                                
                                            <div className="center-wrap">
                                                <Link to={bannerone.BtnLink} className="btn-a">
                                                    <div Style="margin-left:90px; margin-top:-25px;" className="button buttonOutSourcing">
                                                        {bannerone.BtnName}
                                                        <Icofont icon="icofont-long-arrow-right" />
                                                    <div className="mask" /></div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            </VisibilitySensor>
                        </div>
                    </div>
                </div>
            )
        );
        //BannerOne loop END
        
        //Thumbs loop Start
        const thumbdata = this.props.thumbsData.map((thumb, index) => (
            <div Style=" width: 100%;" className="owl-thumb-item" key={index}>
                <Icofont icon={thumb.ThumbIcon} />
                <h3>{thumb.ThumbTitle}</h3>
                {/* <p>{thumb.ThumbContent}</p> */}
            </div>
            )
        );
        //Thumbs loop END
        return (
            <React.Fragment>
                <div id="home"  className="hompage-slides-wrapper">
                    <OwlCarousel
                        className="owl-theme homepage-slides"
                        items={1}
                        touchDrag={true}
                        margin={0}
                        mouseDrag={true}
                        smartSpeed={1500} 
                        paginationSpeed={2800}
                        dotData={true}
                        autoplay={true}
                        dotsContainer={".owl-thumbs"}
                        autoplayTimeout={10000}
                        loop={true}
                        responsiveClass= {true}
                        // Style="width: 100%;"
                  
                        
                    >
                         {/* <div class="item-video" data-merge="3"><a class="owl-video" href="https://vimeo.com/23924346"></a></div> */}
                       {banneronedata} 
                     
                    </OwlCarousel>


                    <div className="owl-thumbs">
                        {thumbdata}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
//Props Types
BannerOne.propTypes = {
    banneronesData: PropTypes.array,
    thumbsData: PropTypes.array
};

//Default Props
BannerOne.defaultProps = {
    banneronesData: [
        {
            BgClass: "single-slider-item outsource-bg-1",
          
            Title: "Think Big! We make IT, possible! Our services will take your business to the next level, Intelligently Designed Websites That Drive Traffic, Leads, and Sales",
           // TopTitle: "Website Development & Support",
            BtnLink:  "/websiteDevelopment",
            BtnName: "get started"
        },
        {
            BgClass: "single-slider-item outsource-bg-2",
           //TopTitle: "Mobile Applications",
            Title: "Creating high impact, result-driven, and user-centered applications to elevate your business. We Think Mobile First.",
            BtnLink:  "/mobileApplication",
            BtnName: "get started"
        },
        {
            BgClass: "single-slider-item outsource-bg-3",
            // TopTitle: "Small Business Solutions",
            Title: "Simplify your Daily Operations & Connect with your customers easier & faster through our Secure, High-efficiency, Interactive Web & Mobile Applications & Solutions.",
            BtnLink:  "/smallBusiness",
            BtnName: "get started"
        },
        {
            BgClass: "single-slider-item outsource-bg-4",
            //TopTitle: "Enterprise Solutions",
            Title: "Maximize your IT investment with IT LAND’s 25+ years’ experience providing professional software solutions, custom designed applications and web design & services.",
            BtnLink:  "/entrpriseSolution",
            BtnName: "get started"
        },
        {
            BgClass: "single-slider-item outsource-bg-5",
           // TopTitle: "E-Commerce Solutions",
            // eslint-disable-next-line no-useless-concat
            Title: "It's more important now than ever to sell online.Trust the"+ " " +"e-Commerce website design experts. Let us help your business succeed online and on all social media applications",
            BtnLink:  "/EcommerceSolution",
            BtnName: "get started"
        },
        {
            BgClass: "single-slider-item outsource-bg-6",
            //TopTitle: "Digital Marketing",
            Title: "It's more important now than ever to bring your business online. Trust the Digital Marketing design experts. Let us help your business succeed & flourish online & on all social media apps.",
            BtnLink:  "/OutSourcingDigitalMarketing",
            BtnName: "get started"
        }
    ],
    thumbsData: [
        {
            ThumbIcon: "icofont-responsive",
            ThumbTitle: "Website Development",
            
            // ThumbContent: "We strive to embrace and drive change in our industry which allows us to keep our clients relevant.",
        },
        {
            ThumbIcon: "icofont-smart-phone",
            ThumbTitle: "Mobile Applications",
           
            // ThumbContent: "We strive to embrace and drive change in our industry which allows us to keep our clients relevant.",
        },
        {
            ThumbIcon: "icofont-company",
            ThumbTitle: "Small Business Solutions",
           
            // ThumbContent: "We strive to embrace and drive change in our industry which allows us to keep our clients relevant.",
        },
        {
            ThumbIcon: "icofont-web",
            ThumbTitle: "Enterprise Solutions",
            
            // ThumbContent: "We strive to embrace and drive change in our industry which allows us to keep our clients relevant.",
        },
        {
            ThumbIcon: "icofont-cart-alt",
            ThumbTitle: "E-Commerce Solutions",
            // ThumbContent: "We strive to embrace and drive change in our industry which allows us to keep our clients relevant.",
        },
        {
            ThumbIcon: "icofont-ui-user-group",
            ThumbTitle: "Digital Marketing",
            // ThumbContent: "We strive to embrace and drive change in our industry which allows us to keep our clients relevant.",
        },
    ]
};

export default BannerOne;
