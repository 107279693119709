import React from "react";
import {  Route, Switch , Redirect } from "react-router-dom";

// components
// import Layout from "./Layout";

// pages
// import Error from "../pages/error";
// import Login from "../pages/login/Login";



// import MainPage from '../pages/mainpage/mainpage';

import { CSSTransition } from "react-transition-group";


// import Dashboard from "../pages/dashboard/Dashboard";
//Package CSS
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";

//Template CSS Style
import "../assets/css/style.css";
import "../assets/css/responsive.css";
import "../assets/css/color/color-red.css";
//Template CSS Style
import "../assets/css/style.css";
import "../assets/css/responsive.css";
// import "../assets/css/color/color-default.css"
//Import Component
import ScrollUpBtn from "../pages/login/components/ScrollUp";
import Page from "react-page-loading";
// import { CSSTransition } from "react-transition-group";
// import DigitalMarketing from "../pages/digitalmarketing/digitalmarketing";

//Package CSS
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";
import OutSourcing from "../pages/outsourcing/OutSourcing";
// import ComputerCourses from "../pages/computercourses/ComputerCourses";
import WebDevelopment from "../pages/websitedevelopment/WebsiteDevelopment";
import MobileApplication from "../pages/mobileapplication/MobileApplication";
import SmallBusiness from "../pages/smallbusiness/SmallBusiness";
import EntrpriseSolution from "../pages/entrprisesolution/EntrpriseSolution";
import EcommerceSoltuion from "../pages/ecommercesolution/EcommerceSolutions";
import DigitalMarketingOutsourcing from "../pages/outsourcingdigital/DigitalMarketingOutSourcing";

export default function App() {

  return (
    
    <div className="App">
    <Page loader={"spin"} color={"#b33541"} size={4}>
        <div>
            <Route
                render={({ location }) => (
                    <CSSTransition
                        key={location.key}
                        timeout={{ enter: 900, exit: 900 }}
                        classNames="fade"
                    >
                        <section className="route-section">
                            <Switch location={location}>
                                   <Route
                                    path="/websitedevelopment"
                                    component={WebDevelopment}
                                />
                                 <Route
                                    path="/mobileApplication"
                                    component={MobileApplication}
                                />
                                <Route 
                                    path="/smallBusiness"
                                    component={SmallBusiness}
                                />
                                <Route
                                    path="/entrpriseSolution"
                                    component={EntrpriseSolution}
                                />
                                <Route
                                    path="/EcommerceSolution"
                                    component={EcommerceSoltuion}
                                />
                                <Route
                                    path="/OutSourcingDigitalMarketing"
                                    component={DigitalMarketingOutsourcing}
                                />
                                  <Route
                                    path="/" 
                                    component={OutSourcing}
                                />
                                <Route path="*" component={OutSourcing} />
                                <Redirect to="/not-found" />
                            </Switch>
                        </section>
                    </CSSTransition>
                )}
            />
            {/* ScrollUpBtn: src/components/ScrollUp.jsx */}
            <ScrollUpBtn />
        </div>
    </Page>
</div>

  );

}
