import React, { Component } from 'react';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';
// import Icofont from 'react-icofont';
// import { Link } from 'react-router-dom';
import tick from "../../../assets/img/tick.png";
import tick2 from '../../../assets/img/tick2.png';
import "react-modal-video/css/modal-video.min.css";


class About extends Component {
    render() {
    return (
        <React.Fragment>
    <section id="offers" className="cta-areacost cta-bg-imgcost">
    <div className="diplay-table">
        <div className="display-table-cell">
            <div className="container">
                <ScrollAnimation animateIn="fadeInUp">
                <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                   <div > <h2>{this.props.Title}</h2></div>
                   <hr/>
                    <div className="col-sm-6 col-md-7 col-lg-7">
                        <div className="cta-infocost">
                                    <ul>
                                        <li><h2><img src={tick} alt =""  Style="width: 3%;" /> {this.props.Content}</h2></li>
                                        <li><h2><img src={tick} alt =""  Style="width: 3%;" /> {this.props.Content1}</h2></li>
                                        <li><h2><img src={tick} alt =""  Style="width: 3%;" /> {this.props.Content2}</h2></li>
                                        <li><h2><img src={tick} alt =""  Style="width: 3%;" /> {this.props.Content3}</h2></li>
                                        <li><h2><img src={tick} alt =""  Style="width: 3%;" /> {this.props.Content4}</h2></li>
                                        <li><h2><img src={tick} alt =""  Style="width: 3%;" /> {this.props.Content5}</h2></li>
                                        <li><h2><img src={tick} alt =""  Style="width: 3%;" /> {this.props.Content6}</h2></li>
                                        <li><h2><img src={tick} alt =""  Style="width: 3%;" /> {this.props.Content7}</h2></li>
                                </ul>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-7 col-lg-5 ">
                                    <div className="cta-infocost">
                                            <ul>
                                                        <li><h2><img src={tick} alt =""  Style="width: 4%;" /> {this.props.Content8}</h2></li>
                                                        <li><h2><img src={tick} alt =""  Style="width: 4%;" /> {this.props.Content9}</h2></li>
                                                        <li><h2><img src={tick} alt =""  Style="width: 4%;" /> We use the most popular e-commerce platforms for an online store.</h2></li>
                                                        <li><h2><img src={tick} alt =""  Style="width: 4%;" /> Programming languages we used: React, &nbsp;Asp.net,  &nbsp;JavaScript,  &nbsp;Java,  &nbsp;HTML5,  &nbsp;CSS3,  &nbsp;and Others.</h2></li>
                                                        <li><h2><img src={tick} alt =""  Style="width: 4%;" /> {this.props.Content12}</h2></li>
                                                        <li><h2><img src={tick} alt =""  Style="width: 4%;" /> {this.props.Content13}</h2></li>
                                            </ul>
                                    </div>
                    </div>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    </div>
</section>
        </React.Fragment>
    );
  }
}
//Props Types
About.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};


//Props Types
About.propTypes = {
    Title: PropTypes.string,
    Content: PropTypes.string,
    Content1: PropTypes.string,
    Content2: PropTypes.string,
    Content3: PropTypes.string,
    Content4: PropTypes.string,
    Content5: PropTypes.string,
    Content6: PropTypes.string,
    Content7: PropTypes.string,
    Content8: PropTypes.string,
    Content9: PropTypes.string,
    Content10: PropTypes.string,
    Content15: PropTypes.string,
    Content11: PropTypes.string,
    Content14: PropTypes.string,
    Content12: PropTypes.string,
    Content13: PropTypes.string,
};

//Default Props
About.defaultProps = {
    SectionbgTitle: "Offers",
    sectionTitle: "What We Offer! ",
    Title: "We offer best in class service for your needs",
    Content: "	Design and Development custom web solutions.",
    Content1: "High performance site development.",
    Content2: "On-site & off-site SEO services.",
    Content3: "Responsive web design.",
    Content4: "User-friendly content management system- CMS. ",
    Content5: "Re-Design and maintenance services.",
    Content6: "Payment processor integrations."  ,
    Content7: "Corporate web based solutions.",
    Content8: "	Custom business website solutions.",
    Content9: "Social media integration.",
    Content10: "We use the most popular e-commerce platforms ", 
    Content15: "for an online store.",
    Content11: "Programming languages we used: React,Asp.net,",
    Content14: "JavaScript,Java, HTML5, CSS3, and Others. ",
    Content12: "Web design services for small business. ",
    Content13: "User experience (UX) design.",
};

export default About;
