import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';

class Services extends Component {
  render() {
      //Service loop start
      const servicedata = this.props.servicesData.map((service, index) => (
        <div className="col-md-6 col-lg-4 text-center" key={index}>
            <div className="service-item">
                <div className="glyph">
                    <Icofont icon={service.icon} />
                </div>
                <h3>{service.heading}</h3>
                <p>{service.description}</p>
            </div>
        </div>
    ));
    //Service loop END
    return (
        <React.Fragment>
            <section id="services" className="services ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center osama-margin">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                        {servicedata}
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
  }
}

//Props Types
Services.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Services.defaultProps = {
    SectionbgTitle: "Services",
    sectionTitle: "Services",
    sectionDescription:
        "A World Class Creative Canadian Company with its Egyptian & US Locations Offering High Quality Services",

    servicesData: [
        {
            icon: "icofont-responsive",
            heading: "Website Development",
            description:
                "Think Big! We make IT, possible! Our services will take your business to the next level, Intelligently Designed Websites That Drive Traffic, Leads, and Sales."
        },
        {

            icon: "icofont-smart-phone",
            heading: "Mobile Applications",
            description:

                "Creating high impact, result-driven, user friendly, and user-centered applications to elevate your business, reach more customers. We Think Mobile First."
        },
        {
            icon: "icofont-company",
            heading: "Small Business Solutions",
            description:
                "Simplify your Daily Operations and Connect with your customers easier and faster Through Our Secure, High-Efficiency, Interactive Web & Mobile Applications and Solutions."
        },
        {
            icon: "icofont-web",
            heading: "Enterprise Solutions",
            description:
                "Maximize your IT investment with IT LAND’s 25+ years’ experience.Streamline your business operations and strengthen your presence in the market with IT LAND’s professional Services."
                
        },
        {
            icon: "icofont-cart-alt",
            heading: "E-Commerce Solutions",
            description:
                "It's more important now than ever to sell online. Trust the e-Commerce website design experts. Let us help your business succeed online and on all social media applications"
        },
        {
            icon: "icofont-ui-user-group",
            heading: "Digital Marketing",
            description:
                "It's more important now than ever to bring your business online. Let us help your business succeed & flourish online and on all social media applications."
        },
    ]
};

export default Services;