import React, { Component } from 'react';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';

import tick from "../../../assets/img/tick.png";
import tick2 from '../../../assets/img/tick2.png';
import "react-modal-video/css/modal-video.min.css";
import AndroidIcon from "../../../assets/img/androidIcon.png";
import IosIcon from "../../../assets/img/appleIcon.png";
import WindowsIcon from "../../../assets/img/windowsIcon.png";

class About extends Component {
    render() {
    return (
        <React.Fragment>
    <section id="offers" className="cta-areacost cta-bg-imgcost">
    <div className="diplay-table">
        <div className="display-table-cell">
            <div className="container">
                <ScrollAnimation animateIn="fadeInUp">
                <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p>{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                   <div> 
                       <h2>{this.props.Title}</h2>
                       <p >Make yourself at home on any mobile platform or reach out to multiple platforms at once.</p>
                       <p Style="font-size: 20px;">Our app development&nbsp;company offers&nbsp;<strong>native and cross-platform mobile development</strong>&nbsp;services:</p>
                   
                   </div>
                   <hr/>
                        <div className="col-sm-12">
                            <div className="cta-infocost">
                                <p>
                                     <ul> <li><h2 Style="color: black; font-weight:normal"><img src={tick} alt =""  Style="width: 2%;" /><img src={tick2} alt =""  Style="width: 0.25%;" /><span Style="font-weight:bold">iOS:</span> {this.props.Content}</h2></li>
                                            <li><h2 Style="color: black; font-weight:normal"><img src={tick} alt =""  Style="width: 2%;" /><img src={tick2} alt =""  Style="width: 0.25%;" /><span Style="font-weight:bold">Android:</span> {this.props.Content1}</h2></li>
                                            <li><h2 Style="color: black; font-weight:normal"><img src={tick} alt =""  Style="width: 2%;" /><img src={tick2} alt =""  Style="width: 0.25%;" /><span Style="font-weight:bold">Cross-platform:</span> {this.props.Content2}</h2></li>
                                             
                                      </ul>
                                      <p Style=" text-align: center; font-size: large; color: #b33541;"> {this.props.Content3}</p>

                                  </p>
                                  <div className="row">
                                  <div className="col-sm-4">
                                    <div class="img" ><img Style="width: 187px; display: block;
                                                                            margin-left: auto;
                                                                            margin-right: auto;" src={AndroidIcon} alt="androidIcon" /></div>
                                    <div className="text-Offer-Os">
                                      <h4 Style="font-size: 18px;">Android Application Development</h4>
                                    </div>
                                  </div>
                                  <div className="col-sm-4 ">
                                  <div class="img" ><img Style="width: 243px; display: block;
                                                                            margin-left: auto;
                                                                            margin-right: auto;" src={IosIcon} alt="AppleIcon" /></div>
                                  <div >
                                      <h4 Style="font-size: 19px;">iPHONE APPLICATION DEVELOPMENT</h4>
                                    </div>
                                  </div> 
                                  <div className="col-sm-4 ">
                                  <div class="img" ><img Style="width: 254px; display: block;
                                                                            margin-left: auto;
                                                                            margin-right: auto;" src={WindowsIcon} alt="androidIcon" /></div>
                                    <div className="text-Offer-Os">
                                      <h4 Style="font-size: 19px; margin-left: 20px;" >Windows Mobile Development</h4>
                                    </div>
                                  </div>
                                  </div>
                            </div>
                        </div>
                      
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    </div>
</section>
        </React.Fragment>
    );
  }
}
//Props Types
About.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Props Types
About.propTypes = {
    Title: PropTypes.string,
    Content: PropTypes.string,
    Content1: PropTypes.string,
    Content2: PropTypes.string,
    Content3: PropTypes.string,
    
   // BtnLink: PropTypes.string,
  //  BtnName: PropTypes.string,
    //Image: PropTypes.string,
    
};

//Default Props
About.defaultProps = {
    SectionbgTitle: "Offers",
    sectionTitle: "What We Offer!",
    Title: "NATIVE AND CROSS-PLATFORM DEVELOPMENT",
    Content: "Build a mobile app that makes the most of Apple’s platform, focusing on outstanding performance and a polished UI.",
    Content1: "Embrace the openness of Android to create a lightning-fast and sleek native app that serves a myriad of devices.",
    Content2: "Cut your development and maintenance costs with cross-platform apps that work flawlessly across iOS and Android.",
    Content3: "Which way do you go Native or Cross-Platform? If you haven’t made up your mind yet, Our Mobile experts will help you figure out what works best for your product. Let’s set up a call and talk about your needs in detail.",
   
};

export default About;