import React, { Component } from 'react';
// import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';
// import { Link } from 'react-router-dom';
import tick from "../../../assets/img/tick.png";

class WelcomeServices extends Component {
    render() {
        return (
            <React.Fragment>
                <section id="welcome" className="cta-areacost cta-bg-imgcost">
                    <div className="diplay-table">
                        <div className="display-table-cell">
                            <div className="container">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="row cost">
                                       < ul>
                                       <li><h1>{this.props.Title1}</h1></li> 
                                       <li><h2>{this.props.Title2}</h2></li> 
                                       <li><h3>{this.props.Title3}</h3></li> 
                                        </ul>
                                    <p> Depending on your needs a Website/Software Application can cost anywhere between $500 to $500,000+. Pricing variables that come into play include complexity of design, programming needs, custom functionality, data imports, data integrations and more. The best way to get an accurate cost for your Website/Software Application project is to talk with a web design expert in detail.
So, When building a new Website/Software Application, how do you know what to budget? It's difficult to determine Website/Software Application pricing as many variables come into play, but we'll outline what to expect and the differences in pricing levels. Understand what a Website/Software Application costs will allow you to select the best Website/Software Application services provider that matches your budget and goals.
</p>
<h2>{this.props.Title}</h2>
                                        <div className="col-sm-6 col-md-7 col-lg-7">
                                            <div className="cta-infocost">
                                           
                                                
                                                
                                                <p> <ul>
                                   <li><img src={tick} alt =""  Style="width: 3%;" /> {this.props.Content}</li>
                                    <li><img src={tick} alt =""  Style="width: 3%;" /> {this.props.Content1}</li>
                                   <li><img src={tick} alt =""  Style="width: 3%;" /> {this.props.Content2}</li>
                                   <li><img src={tick} alt =""  Style="width: 3%;" /> {this.props.Content3}</li>
                                    
                                   
                                       </ul>
                                       
                                       </p>
                                                
                                                {/* <div className="center-wrap">
                                                    <Link to={this.props.BtnLink} className="btn-a">
                                                        <div className="button">
                                                            {this.props.BtnName}
                                                            <Icofont icon="icofont-long-arrow-right" />
                                                        <div className="mask" /></div>
                                                    </Link>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-5 col-lg-5 ">
                                        <div className="cta-infocost">
                                           
                                            <p> <ul>
                                            <li><img src={tick} alt =""  Style="width: 4%;" /> {this.props.Content4}</li>
                                   <li><img src={tick} alt =""  Style="width: 4%;" /> {this.props.Content5}</li>
                                   <li><img src={tick} alt =""  Style="width: 4%;" /> {this.props.Content6}</li>
                                    <li><img src={tick} alt =""  Style="width: 4%;" /> {this.props.Content7}</li>
                                    </ul>
                                       
                                       </p>
                                        </div>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

//Props Types
WelcomeServices.propTypes = {
    Title: PropTypes.string,
    Title1: PropTypes.string,
    Title2: PropTypes.string,
    Title3: PropTypes.string,
    Content: PropTypes.string,
    Content1: PropTypes.string,
    Content2: PropTypes.string,
    Content3: PropTypes.string,
    Content4: PropTypes.string,
    Content5: PropTypes.string,
    Content6: PropTypes.string,
    Content7: PropTypes.string,
    // BtnLink: PropTypes.string,
    // BtnName: PropTypes.string,
   // Image: PropTypes.string,
    
};

//Default Props
WelcomeServices.defaultProps = {
    Title: "Items That Determine Website/Software Application Cost:",
    Title1: "Website/Software Application Pricing Guide:",
    Title2: "How Much Should a Website/Software Application Cost in 2020?",
    Title3: "Website/Software Application pricing and Website/Software Application costs ",
    Content: "	How do you want your Website/Software Application to look?",
    Content1: "How many pages do you need?",
    Content2: "What platform will your Website/Software App. be on? (React, Wordpress, Shopify, etc.)",
    Content3: "Who will put your content into the Website/Software Application (You agency or will you?)",
    Content4: "Do you need advanced CMS capabilities?",
    Content5: "Will you Website/Software App. integrate with other Softwares?",
    Content6: "Do you need to process payments online? (E-Commerce)",
    Content7: "Do you have custom functionality that's needed?",
//    BtnLink: "/#0",
//    BtnName: "PURCHASE NOW",
   // Image: require("../../../assets/img/lap.png"),
};
export default WelcomeServices;