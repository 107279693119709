import React, { Component } from 'react';
// import Icofont from 'react-icofont';
// import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
import tick from '../../../../assets/img/tick.png';
// import videoaya from "../../assets/video.wmv"
import tick2 from '../../../../assets/img/tick2.png';
import img from "../../../../assets/img/Comp-1.gif";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
class BannerThree extends Component {
    render() {
        return (
            <React.Fragment>
                <div id="home" className="home-video-area">
                {/* <video id="bgvid" loop autoPlay muted>
                    <source src="http://taxify.co/wp-content/uploads/2014/06/file.mp4" type="video/mp4" />
                </video> */}
                 
            <div class="carousel-wrapper">
            <Carousel showArrows={true} showIndicators={false} showThumbs={false} showStatus={false} autoPlay={true} >
                <div>
                <img src={img} alt="MISSING JPG"/> 
                </div>
                {/* <div>
                <img src={img} alt="Hong Kong" />
                </div>
                <div>
                <img src={img} alt="Hong Kong" />
                </div> */}
            </Carousel>
        </div>
                
                    <div className="video-text-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <span className="hero-text animated fadeInDown slow opacityOne">
                                        {this.props.TopTitle}
                                    </span>
                                    <div className="container">
                                    <h1
                                        className="animated fadeInDown slow opacityOne">
                                        {this.props.Title}
                                    </h1>
                                    </div>
                                    <div className="row">
                                    <p
                                    className=" animated fadeInDown slow opacityOne OsamaPar ">
                                          {this.props.Title1}
                                        </p>
                                     </div>
                                    <p
                                        className="animated fadeInDown slow opacityOne ayanawar">
                                             <ul>
                                   <li><img src={tick} alt =""  Style="width: 4%;" /><img src={tick2} alt =""  Style="width: 1%;" /> {this.props.Content}</li>
                                    <li><img src={tick} alt =""  Style="width: 4%;" /><img src={tick2} alt =""  Style="width: 1%;" /> {this.props.Content1}</li>
                                   <li><img src={tick} alt =""  Style="width: 4%;" /> <img src={tick2} alt =""  Style="width: 1%;" />{this.props.Content2}</li>
                                   <li> <img src={tick2} alt =""  Style="width: 4%;" /><img src={tick2} alt =""  Style="width: 1%;" />{this.props.Content3}</li>
                                       </ul>
                                        
                                    </p>
                                    {/* <div className="center-wrap">
                                        <Link to={this.props.BtnLink} className="btn-a">
                                            <div className="button">
                                                {this.props.BtnName}
                                                <Icofont icon="icofont-long-arrow-right" />
                                            <div className="mask" /></div>
                                        </Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </React.Fragment>
        );
    }
}
//Props Types
BannerThree.propTypes = {
    Title: PropTypes.string,
    Title1: PropTypes.string,
    TopTitle: PropTypes.string,
   
    Content: PropTypes.string,
    Content1: PropTypes.string,
    Content2: PropTypes.string,
    Content3: PropTypes.string,
    //BtnLink: PropTypes.string,
   // BtnName: PropTypes.string,
};

//Default Props
BannerThree.defaultProps = {
    TopTitle: "",
    Title:"" ,
    Title1:"Capture more leads with a conversion-focused website custom-designed to help you achieve your business goals and objectives." ,
   Content: "Lead-generation and conversion-focused, data-driven web design.",
   Content1: "Responsive design optimized for all browsers and devices .",
   Content2: "User-friendly Content Management System (CMS) makes editing",
   Content3: " & adding new content a breeze.",
   // BtnLink:  "/#",
   // BtnName: "webSiteDevelopment",
};
export default BannerThree;
