import React, { Component } from 'react';
// import Icofont from 'react-icofont';
// import { Link } from 'react-router-dom';
import PropTypes from "prop-types";
// import tick from '../../../../assets/img/tick.png';
// import videoaya from "../../assets/video.wmv"

import img from "../../../../assets/img/DIGITAL-MARKETINGOut.gif";

// import img from "../../../../assets/img/digitalOut.gif";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
class BannerThree extends Component {
    render() {
        return (
            <React.Fragment>
                <div id="home" className="home-video-area">
              
                 
            <div class="carousel-wrapper">
            <Carousel showArrows={true} showIndicators={false} showThumbs={false} showStatus={false} autoPlay={true} >
                <div>
                <img src={img} alt="MISSING JPG"/> 
                </div>
            </Carousel>
        </div>
                
                    <div className="video-text-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                   
                                    <div className="container" Style="margin-left: 122px;"> 
                                    <h1
                                        className="animated fadeInDown slow opacityOne osama-res-digital" Style="margin-top: 54px;"
                                    >
                                        {this.props.Title}
                                    </h1>
                                    </div>
                                    <div className="row">
                                  
                                        <p className=" animated fadeInDown slow opacityOne" Style="font-size: 19px;">Our team of marketing experts specializes in all forms of Digital Marketing including paid per click, <span class="sc-1_4_4-link__content">search engine marketing</span>, <span class="sc-1_4_4-link__content">social media marketing</span> and more. Our campaign&nbsp;management solutions are focused on maximizing ROI from your online investment in every stage of the buying journey. We develop the most cost-effective way to reach your audience with the right message at the right time.</p>
                                     </div>
                                    
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </React.Fragment>
        );
    }
}
//Props Types
BannerThree.propTypes = {
    Title: PropTypes.string,
    Title1: PropTypes.string,
    TopTitle: PropTypes.string,
   
    Content: PropTypes.string,
    Content1: PropTypes.string,
    Content2: PropTypes.string,
    //BtnLink: PropTypes.string,
   // BtnName: PropTypes.string,
};

//Default Props
BannerThree.defaultProps = {
    TopTitle: "",
    Title:"Digital Marketing" ,
    Title1:"Capture more leads with a conversion-focused website custom-designed to help you achieve your business goals and objectives." ,
   Content: "Lead-generation and conversion-focused, data-driven web design.",
   Content1: "Responsive design optimized for all browsers and devices .",
   Content2: "User-friendly Content Management System (CMS) makes editing & adding new content a breeze.",
   // BtnLink:  "/#",
   // BtnName: "webSiteDevelopment",
};
export default BannerThree;
