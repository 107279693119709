import React, { Component } from 'react';
import PropTypes from "prop-types";
// import tick from '../../../../assets/img/tick.png';
import img from "../../../../assets/img/Small-Out.gif";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
class BannerThree extends Component {
    render() {
        return (
            <React.Fragment>
                <div id="home" className="home-video-area">
               
                 
            <div class="carousel-wrapper">
            <Carousel showArrows={true} showIndicators={false} showThumbs={false} showStatus={false} autoPlay={true} >
                <div>
                <img src={img} alt="MISSING JPG"/> 
                </div>
            </Carousel>
        </div>
                
                    <div className="video-text-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                   
                                    <div className="container">

                                    <h1
                                        className="animated fadeInDown slow opacityOne ayasmall smallBusTopTitle" Style=" font-size: 27px;
                                        font-weight: bold;">
                                        {this.props.Title}
                                    </h1>
                                    </div>
                                    <div className="row">
                                    <p
                                    className=" animated fadeInDown slow opacityOne osamasmall " Style="
                                        display: block;
                                        font-size: 23px;
                                        font-weight: 300;
                                        margin-top: -42px;
                                        margin-left: 59px;
                                    ">
                                          {this.props.Title1}
                                        </p>
                                     </div>
                                    <p
                                        className="animated fadeInDown slow opacityOne ayanawar">
                                             <ul>
                                   <li> {this.props.Content}</li>
                                    <li>{this.props.Content1}</li>
                                   <li> {this.props.Content2}</li>
                                       </ul>
                                        
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </React.Fragment>
        );
    }
}
//Props Types
BannerThree.propTypes = {
    Title: PropTypes.string,
    Title1: PropTypes.string,
    TopTitle: PropTypes.string,
   
    Content: PropTypes.string,
    // Content1: PropTypes.string,
    // Content2: PropTypes.string,
};

//Default Props
BannerThree.defaultProps = {
    TopTitle: "Ready to take your business to the next level?",
    Title:"Ready to take your business to the next level?" ,
    Title1:"Reach out today for a one-on-one meeting!" ,
   Content: "Digital strategies start with a website, but they certainly don’t end there. Once we help you with an amazing website design, we can go above and beyond to help with your digital marketing and content strategies as well. Reach out to see how we can help. Contact us today to further discuss your website and/or digital marketing needs!",
//    Content1: "Responsive design optimized for all browsers and devices .",
//    Content2: "User-friendly Content Management System (CMS) makes editing & adding new content a breeze.",
 
};
export default BannerThree;
