import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';

class Services extends Component {
  render() {
      //Service loop start
      const servicedata = this.props.servicesData.map((service, index) => (
        <div className="col-md-6 col-lg-4 text-center" key={index}>
            <div className="service-item">
                <div className="glyph">
                    <Icofont icon={service.icon} />
                </div>
                <h3>{service.heading}</h3>
                <p>{service.description}</p>
            </div>
        </div>
    ));
    //Service loop END
    return (
        <React.Fragment>
            <section id="services" className="services ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    {/* <p>{this.props.sectionDescription}</p> */}
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="row">
                        {servicedata}
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
  }
}

//Props Types
Services.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Services.defaultProps = {
    SectionbgTitle: "Services",
    sectionTitle: "Services",
    sectionDescription:
        "Capture more leads with a conversion-focused website custom-designed to help you achieve your business goals and objectives.",

    servicesData: [
        {
            icon: "icofont-info-circle",
            heading: "Gather Information",
            description:
                "What is the purpose of the site and What kind of information will the target audience be looking for on your site.., What do you hope to accomplish by building this web site"
        },
        {
            icon: "icofont-memorial",
            heading: "Planning",
            description:
                "For any Web design Company using the information gathered from phase one, we put together a plan for your web site. Here we develop a site map – "
        },
        {
            icon: "icofont-edit",
            heading: "Website Design",
            description:
                "Drawing from the information gathered up to this point, we determine the look and feel of the site. Target audience is one of the key factors taken into consideration here."
        },
        {
            icon: "icofont-code",
            heading: "Website Development",
            description:
                "This is where the website itself is created. We take all of the individual graphic elements from the prototype and use them to create the functional website."
        },
        {
            icon: "icofont-verification-check",
            heading: "Testing and launching",
            description:
                "At this point, we attend to the final details and test your web site, we test things such as the complete functionality of forms or other scripts, we test for last minute and then Launching"
        },
        {
            icon: "icofont-repair",
            heading: "Maintenance & Manage",
            description:
                "The development of your web site is not necessarily over, though. One way to bring repeat visitors to your site is to offer new products on a regular basis, if this interests you."
        },
    ]
};

export default Services;
