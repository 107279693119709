import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import ScrollAnimation from 'react-animate-on-scroll';

class Services extends Component {
  render() {
      //Service loop start
      const servicedata = this.props.servicesData.map((service, index) => (
        <div className="col-sm-3 text-center" key={index}>
            <div className="service-item">
                <div className="glyph">
                    <Icofont icon={service.icon} />
                </div>
                <h3 Style="font-size: 19px;">{service.heading}</h3>
                <p>{service.description}</p>
            </div>
        </div>
    ));
    //Service loop END
    return (
        <React.Fragment>
            <section id="services" className="services ptb-100">
                <div className="container">
                    <div className="row" Style="margin-top: -35px;">
                        <div className="col-lg-8 offset-lg-2 text-center">
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="section-title">
                                    <h2>{this.props.sectionTitle}</h2>
                                    <p Style="margin-top: -25px;">{this.props.sectionDescription}</p>
                                    <span className="section-title-bg">{this.props.SectionbgTitle}</span>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div Style="margin-top: -15px;" className="row">
                        {servicedata}
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
  }
}

//Props Types
Services.propTypes = {
    SectionbgTitle: PropTypes.string,
    sectionTitle: PropTypes.string,
    sectionDescription: PropTypes.string,
    servicesData: PropTypes.array
};

//Default Props
Services.defaultProps = {
    SectionbgTitle: "Services",
    sectionTitle: "Services",
    sectionDescription:
        "Bringing All Tools of Advertising Under One Click",

    servicesData: [
        {
            icon: "icofont-chart-arrows-axis",
            heading: "Marketing Strategy",
            description:
                "Always depend on the market research first by our team to reach with our client to the company goals, A good marketing strategy should be drawn from market research and focus on the right product mix in order to achieve the maximum profit potential"
        },
        {
            icon: "icofont-brand-designbump",
            heading: "Branding & Rebranding",
            description:
                "IT-Land is a leading supplier of branded corporate premiums . We are specialized in customized products that cover a wide spectrum of ideas. All our products are paramount in quality and design."
        },
        {
            icon: "icofont-sale-discount",
            heading: "Direct Marketing & Sales",
            description:
                "Provided to consumers in order to communicate information about a product or service. Seeks to elicit “A call to action” from the customer like visit or purchase. It includes postal mail, telemarketing and direct email marketing."
        },
        {
            icon: "icofont-presentation",
            heading: "Digital Marketing",
            description:
                "Digital is a means for customers to find out more about a company than what the ad says. This has become an opportunity for all departments to jump on the digital communication bandwagon ranging from sales, to HR, customer services, research and innovation among many others."
        },
        {
            icon: "icofont-magic",
            heading: "Advertising",
            description:
                "Perform consumer research to discover other ways of reaching customers. Developing and managing digital marketing campaigns. Review new technologies and keep the company in development."
        },
        {
            icon: "icofont-video",
            heading: "Video & Photography",
            description:
                "Collaborates with clients and production staff to produce a variety of video products. Plans and directs assigned studio productions. Adjusts various equipment like microphones, lighting and props."
        },
        
        {
            icon: "icofont-users-social",
            heading: "Public Relations (PR)",
            description:
                "Promote companies or individuals via editorial coverage. Planning and implementing the organization’s efforts to influence public policy. Managing the spread of information between companies"
        },
        
        {
            icon: "icofont-company",
            heading: "Event Organizing",
            description:
                "Designing a theme for an activity, occasion or exhibit. Involves face-to-face contact between companies and customers at special events such as concerts, fairs, and sporting events. Provides value to attendees beyond information about a product or service."
        },
    ]
};

export default Services;
